import create from "zustand";
import AuthInformation from "../types/AuthInformation";

interface AuthStore {
  authInformation: AuthInformation | null | undefined;
  setAuthInformation: (authInformation: AuthInformation | null) => void;
}

const authStore = create<AuthStore>((set) => ({
  authInformation: undefined,
  setAuthInformation: (authInformation: AuthInformation | null) => set({ authInformation }),
}));

export default authStore;
