import IsoLanguage from "../types/IsoLanguage";

export const isoLanguages: IsoLanguage[] = [
  { code: "de", name: "German (Standard)" },
  { code: "en-gb", name: "English (United Kingdom)" },
  { code: "fr-be", name: "French (Belgium)" },
  { code: "nl-be", name: "Dutch (Belgium)" },
  { code: "es", name: "Spanish (Spain)" },
  { code: "it", name: "Italian (Standard)" },
];
