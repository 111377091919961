import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Link, Location, useLocation } from "react-router-dom";
import RouteDefinition from "../../../types/RouteDefinition";
import useGetCurrentRoute from "../../../hooks/useGetCurrentRoute";
import { useTranslation } from "react-i18next";

type Props = {
  routes: RouteDefinition[];
};

type State = {
  previousPath: string;
};

export default function MainContentHeader(props: Readonly<Props>): JSX.Element {
  const { t } = useTranslation();
  const location: Location = useLocation();
  const currentRoute: RouteDefinition | undefined = useGetCurrentRoute(props.routes);
  const state = location.state as State;

  return (
    <Box>
      {currentRoute && (
        <Box>
          {currentRoute.headerButton && (
            <Button
              id="content-header-button"
              color="secondary"
              variant="contained"
              startIcon={<ArrowBackIos />}
              component={Link}
              to={state?.previousPath ?? currentRoute.headerButton.path}
              sx={{ mb: 3 }}
            >
              {(state?.previousPath && currentRoute.headerButton.contentCustom) ??
                t(currentRoute.headerButton.content)}
            </Button>
          )}
          {currentRoute.title && (
            <Typography id="content-header-title" variant="h4" color="secondary" sx={{ mb: 1 }}>
              {t(currentRoute.title)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
