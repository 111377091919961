import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import theme from "./theme";
import authInstance from "./lib/authInstance";
import { AxiosInterceptor } from "./lib/bffApi";
import "./index.css";
import "./i18n";

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={authInstance}>
        <BrowserRouter>
          <AxiosInterceptor>
            <CssBaseline />
            <App />
          </AxiosInterceptor>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>
);
