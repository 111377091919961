import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import getMyUsersAsync from "../api/getMyUsersAsync";
import UserResponse from "../types/UserResponse";
import UserRoleResponse from "../../user/types/UserRoleResponse";
import UserGridResponse from "../types/UserGridResponse";

export default function UsersOverview(): JSX.Element {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserGridResponse[] | null>();
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    (async () => {
      if (!users) {
        const usersResponse = await getMyUsersAsync();
        setUsers([
          ...usersResponse.map((u: UserResponse) => {
            return {
              ...u,
              id: u.objectId,
              userRoles: u.userRoles?.map((r: UserRoleResponse) => r.role.name).join(", "),
              preferredLanguage: u.preferredLanguage?.name,
            };
          }),
        ]);
      }
    })();
  }, [users]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
    },
    {
      field: "emailAddress",
      headerName: t("email-address"),
      flex: 1,
    },
    {
      field: "userRoles",
      headerName: t("user-roles"),
      flex: 2,
    },
    {
      field: "preferredLanguage",
      headerName: t("preferred-language"),
      flex: 1,
    },
    {
      field: "timezone",
      headerName: t("time-zone"),
      flex: 1,
    },
    {
      field: "options",
      headerName: "",
      type: "actions",
      align: "right",
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={params.id.toString()}
            icon={<EditIcon />}
            id="update-user-edit-button"
            label={t("edit")}
            onClick={() => {
              navigate(`/users/${params.id.toString()}/edit`);
            }}
          />,
        ];
      },
    },
  ];

  function quickSearchToolbar() {
    return (
      <Box sx={{ display: "flex" }}>
        <GridToolbarQuickFilter
          sx={{ flex: 1 }}
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
          debounceMs={500}
        />
      </Box>
    );
  }

  if (users === undefined) {
    return <LinearProgress id="users-loading" />;
  }

  if (users?.length === 0) {
    return <Typography id="users-not-found">{t("no-company-users-found")}</Typography>;
  }

  return (
    <Box>
      {users && (
        <Box sx={{ height: 500 }}>
          <DataGrid
            columns={columns}
            rows={users}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: quickSearchToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
