import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Box, Button, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import useSnackbarStore from "../../../stores/snackbarStore";
import ConfirmDialog from "../../layout/components/ConfirmDialog";
import listUserInvitationsAsync from "../api/listUserInvitationsAsync";
import deleteUserInvitationAsync from "../api/deleteUserInvitationAsync";
import UserInvitationResponse from "../types/UserInvitationResponse";
import UserInvitationRoleResponse from "../types/UserInvitationRoleResponse";
import UserInvitationGridResponse from "../types/UserInvitationGridResponse";
import UserInvitationState from "../types/UserInvitationState";

export default function UserInvitationsOverview(): JSX.Element {
  const { t } = useTranslation();
  const [userInvitations, setUserInvitations] = useState<UserInvitationGridResponse[] | null>();
  const navigate: NavigateFunction = useNavigate();
  const [deleteUserInvitationId, setDeleteUserInvitationId] = useState<string | null>();

  useEffect(() => {
    (async () => {
      if (!userInvitations) {
        const userInvitationResponse = await listUserInvitationsAsync(UserInvitationState.Pending);
        setUserInvitations([
          ...userInvitationResponse.map((u: UserInvitationResponse) => {
            return {
              ...u,
              roles: u.roles?.map((r: UserInvitationRoleResponse) => r.role.name).join(", "),
              preferredLanguage: u.preferredLanguage?.name,
            };
          }),
        ]);
      }
    })();
  }, [userInvitations]);

  const columns: GridColDef[] = [
    {
      field: "invitedEmail",
      headerName: t("invited-email"),
      flex: 1,
    },
    {
      field: "roles",
      headerName: t("user-invitation-roles"),
      flex: 2,
    },
    {
      field: "preferredLanguage",
      headerName: t("preferred-language"),
      flex: 1,
    },
    {
      field: "timezone",
      headerName: t("time-zone"),
      flex: 1,
    },
    {
      field: "options",
      headerName: "",
      type: "actions",
      align: "right",
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={`edit-${params.id.toString()}`}
            icon={<EditIcon />}
            id="update-user-invitation-edit-button"
            label={t("edit")}
            onClick={() => {
              navigate(`/user-invitations/${params.id.toString()}/edit`);
            }}
          />,
          <GridActionsCellItem
            key={`delete-${params.id.toString()}`}
            icon={<DeleteIcon />}
            id="delete-user-invitation-button"
            label={t("delete")}
            onClick={() => {
              setDeleteUserInvitationId(params.id.toString());
            }}
          />,
        ];
      },
    },
  ];

  function onClickCreate() {
    navigate(`/user-invitations/create`);
  }

  function quickSearchToolbar() {
    return (
      <Box sx={{ display: "flex" }}>
        <GridToolbarQuickFilter
          sx={{ flex: 1 }}
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
          debounceMs={500}
        />
      </Box>
    );
  }

  async function handleCloseDeleteUserInvitationConfirmDialog(isConfirmed: boolean) {
    if (isConfirmed && deleteUserInvitationId) {
      const response = await deleteUserInvitationAsync(deleteUserInvitationId);
      if (response && userInvitations) {
        setUserInvitations(userInvitations.filter((x) => x.id !== deleteUserInvitationId));
        useSnackbarStore.getState().addSuccess(t("successfully-deleted-user-invitation"));
      }
    }
    setDeleteUserInvitationId(null);
  }

  if (userInvitations === undefined) {
    return <LinearProgress id="user-invitations-loading" />;
  }

  return (
    <Box>
      <Button
        id="user-invitations-create-button"
        onClick={onClickCreate}
        color="primary"
        variant="contained"
        sx={{ mb: 2 }}
      >
        {t("new-user-invitation")}
      </Button>
      {userInvitations && userInvitations.length > 0 && (
        <Box sx={{ height: 500 }}>
          <DataGrid
            columns={columns}
            rows={userInvitations}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: quickSearchToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
      )}

      <ConfirmDialog
        dialogId="user-invitations-delete-confirm-dialog"
        title="delete-user-invitation"
        description={"you-sure-to-delete-user-invitation"}
        confirmDialogOpen={deleteUserInvitationId != null}
        handleCloseConfirmDialog={handleCloseDeleteUserInvitationConfirmDialog}
      />
    </Box>
  );
}
