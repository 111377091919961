import bffApi from "../../../lib/bffApi";
import UpsertCommunityUserRolesRequest from "../types/UpsertCommunityUserRolesRequest";

export async function upsertCommunityUserRolesAsync(
  userObjectId: string,
  request: UpsertCommunityUserRolesRequest
): Promise<boolean> {
  const response = await bffApi.post(`communityuserroles/users/${userObjectId}`, request);
  return (response as unknown as string) === "";
}
