import GroupIcon from "@mui/icons-material/Group";
import RouteDefinition from "../../../types/RouteDefinition";
import { communityAdmin, eclicCommunityAdmin } from "../../../config/roles";
import CommunityUserRolesOverview from "./CommunityUserRolesOverview";

export const routes: RouteDefinition[] = [
  {
    path: "/community",
    element: <CommunityUserRolesOverview />,
    title: "community-user-roles",
    menuTitle: "community",
    icon: <GroupIcon id="groupIcon" />,
    showInSidebar: true,
    allowedRoles: [communityAdmin, eclicCommunityAdmin],
  },
];
