import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NotFound(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography id="not-found-title" variant="h3" color="secondary">
        {t("page-not-found")}
      </Typography>
      <Typography id="not-found-info">{t("left-sidebar-navigate-pages")}</Typography>
    </Box>
  );
}
