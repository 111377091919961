import MyEecd from "./MyEecd";
import RouteDefinition from "../../../types/RouteDefinition";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ninaConsoleAdmin, ninaConsoleUser } from "../../../config/roles";

export const routes: RouteDefinition[] = [
  {
    path: "/my-eecd",
    element: <MyEecd />,
    title: "my-eecd",
    icon: <DashboardIcon id="dashboardIcon" />,
    showInSidebar: true,
    myEecdRoute: true,
    allowedRoles: [ninaConsoleUser, ninaConsoleAdmin],
  },
];
