import Homepage from "./Homepage";
import RouteDefinition from "../../../types/RouteDefinition";

export const routes: RouteDefinition[] = [
  {
    path: "/",
    element: <Homepage />,
    showInSidebar: false,
  },
];
