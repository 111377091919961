import { Box, Drawer } from "@mui/material";
import SidebarContent from "./SidebarContent";
import { sidebarWidth } from "../config";
import RouteDefinition from "../../../types/RouteDefinition";
import { useIsAuthenticated } from "@azure/msal-react";

type Props = {
  isSidebarOpen: boolean;
  onClickSidebarToggle(): void;
  routes: RouteDefinition[];
};

export default function Sidebar(props: Readonly<Props>): JSX.Element {
  const isAuthenticated: boolean = useIsAuthenticated();
  return (
    <Box component="nav" sx={{ width: { sm: `${sidebarWidth}px` }, flexShrink: { sm: 0 } }}>
      {isAuthenticated && (
        <Drawer
          variant="temporary"
          open={props.isSidebarOpen}
          onClose={props.onClickSidebarToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: `${sidebarWidth}px` },
          }}
        >
          <SidebarContent routes={props.routes} />
        </Drawer>
      )}
      <Drawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: `${sidebarWidth}px` },
        }}
      >
        <SidebarContent routes={props.routes} />
      </Drawer>
    </Box>
  );
}
