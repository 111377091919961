import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type Props = {
  buttonId: string;
  href: string;
  text: string;
  icon: JSX.Element;
};

export default function SidebarExternalItem(props: Readonly<Props>): JSX.Element {
  return (
    <ListItem disablePadding>
      <ListItemButton id={props.buttonId} component="a" target="_blank" href={props.href}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.text} />
      </ListItemButton>
    </ListItem>
  );
}
