import create from "zustand";
import CompanyDataResponse from "../features/company/types/CompanyDataResponse";

interface CompanyDataStore {
  companyData: CompanyDataResponse | null | undefined;
  setCompanyData: (companyData: CompanyDataResponse | null) => void;
}

const companyDataStore = create<CompanyDataStore>((set) => ({
  companyData: undefined,
  setCompanyData: (companyData: CompanyDataResponse | null) => set({ companyData }),
}));

export default companyDataStore;
