import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Homepage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Typography id="welcome-text" variant="h3" color="secondary">
      {t("welcome-to-eecd-portal")}
    </Typography>
  );
}
