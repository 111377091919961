import { AuthenticationResult } from "@azure/msal-common";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import useSnackbarStore from "../stores/snackbarStore";
import { bffRequest, protectedResources } from "../config/authConfig";
import ProblemDetails from "../types/ProblemDetails";
import { AccountInfo, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import getSignInAccount from "../utils/getSignInAccount";

const bffApi = axios.create({
  baseURL: protectedResources.bff.endpoint,
  headers: { "Content-Type": "application/json" },
  hideSnackbarErrors: false,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AxiosInterceptor = ({ children }: any) => {
  const navigate: NavigateFunction = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    const getTokenRequest = (): SilentRequest => {
      // We always want a token request for the signed in account, not an edit-profile account (claims enrichment only works for the sign-in policy).
      // Use the default bff request as a fallback and let our api handle the authorization accordingly.
      const signInAccount: AccountInfo | null = getSignInAccount();
      return signInAccount ? { ...bffRequest, account: signInAccount } : bffRequest;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestResultInterceptor = async (config: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> => {
      if (config.headers) {
        try {
          const authenticationResult: AuthenticationResult = await instance.acquireTokenSilent(getTokenRequest());
          config.headers.Authorization = `Bearer ${authenticationResult.accessToken}`;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(getTokenRequest());
          }
          return config; // BFF will eventually return unauthorized
        }
      }
      return config;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseResultInterceptor = (response: AxiosResponse<any, any>): AxiosResponse<any, any> => {
      return response.data;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseErrorInterceptor = (error: AxiosError<unknown, any>) => {
      if (error.config?.hideSnackbarErrors) {
        return error;
      }
      if (error.code === AxiosError.ERR_NETWORK) {
        useSnackbarStore.getState().addError("Request could not be sent.");
      } else if (error.code === AxiosError.ERR_CANCELED) {
        useSnackbarStore.getState().addInfo("Request cancelled.");
      } else if (error.response) {
        switch (error.response.status) {
          case 400: {
            const fallbackMessage: string = "Your request is not valid.";
            try {
              const badRequest = error.response.data as ProblemDetails;
              useSnackbarStore.getState().addError(badRequest.detail ?? fallbackMessage);
            } catch {
              useSnackbarStore.getState().addError(fallbackMessage);
            }
            break;
          }
          case 401:
            useSnackbarStore.getState().addError("You're not authorized to perform this action.");
            break;
          case 403:
            navigate("/forbidden");
            break;
          case 404: {
            if (["POST", "PUT", "DELETE"].includes(error.config.method?.toUpperCase() ?? "")) {
              const fallbackMessage: string = "Not found.";
              try {
                const badRequest = error.response.data as ProblemDetails;
                if (badRequest.detail) {
                  useSnackbarStore.getState().addError(badRequest.detail);
                } else {
                  useSnackbarStore.getState().addWarning(fallbackMessage);
                }
              } catch {
                useSnackbarStore.getState().addWarning(fallbackMessage);
              }
            }
            // Some pages might depend on the value to show the 'No xxx found' message.
            return null;
          }
          case 500: {
            const fallbackMessage: string = "Something went wrong while performing this action.";
            try {
              const internalServerError = error.response.data as ProblemDetails;
              useSnackbarStore.getState().addError(internalServerError.detail ?? fallbackMessage);
            } catch {
              useSnackbarStore.getState().addError(fallbackMessage);
            }
            break;
          }
          default:
            useSnackbarStore.getState().addError("Something went wrong.");
        }
      }
    };

    const requestInterceptor = bffApi.interceptors.request.use(requestResultInterceptor);
    const responseInterceptor = bffApi.interceptors.response.use(
      responseResultInterceptor,
      responseErrorInterceptor
    );

    return () => {
      bffApi.interceptors.request.eject(requestInterceptor);
      bffApi.interceptors.response.eject(responseInterceptor);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default bffApi;
export { AxiosInterceptor };
