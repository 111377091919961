import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CompanyLogoSvg from "../assets/company-logo.svg";

export default function CompanyLogoLink(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Link to="/">
      <img id="company-logo" src={CompanyLogoSvg} alt={t("company-logo")} style={{ height: "50px" }} />
    </Link>
  );
}
