import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AccountInfo, AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import IdTokenClaims from "../types/IdTokenClaims";
import useAuthStore from "../stores/authStore";
import { NavigateFunction, matchPath, useNavigate } from "react-router-dom";
import AuthInformation from "../types/AuthInformation";
import { b2cPolicies, authWhitelistPaths } from "../config/authConfig";
import getSignInAccount from "../utils/getSignInAccount";
import Role from "../types/Role";
import { isUserInEclicCommunityAsync } from "../utils/isUserInEclicCommunityAsync";
import { isUserEclicCommunityAdmin } from "../utils/isUserEclicCommunityAdmin";
import { isUserCommunityAdminAsync } from "../utils/isUserCommunityAdminAsync";
import { isEecdCompanyAdminAsync } from "../utils/isEecdCompanyAdminAsync";
import isUserAdministratorAsync from "../utils/isUserAdministratorAsync";

export default function useConfigureAuthCache(): void {
  const isAuthenticated: boolean = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const navigate: NavigateFunction = useNavigate();
  const { setAuthInformation } = useAuthStore();

  useEffect(() => {
    (async () => {
      if (inProgress === InteractionStatus.None) {
        instance
          .handleRedirectPromise()
          .then((response: AuthenticationResult | null) => {
            if (isAuthenticated) {
              configureCache(response);
            }
          })
          .catch(() => {
            if (isAuthenticated) {
              configureCache();
            } else {
              navigate("/");
            }
          });
      }
    })();
    // Including instance (from msal) can trigger infinite loop (we might retrieve a token on this instance, causing it to change)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, isAuthenticated]);

  async function configureCache(response?: AuthenticationResult | null): Promise<void> {
    if (response) {
      const idTokenClaims: IdTokenClaims = response.idTokenClaims;

      // When the user has followed the forgot password flow, it doesn't contain our extra claims (enrichment only works for the sign-in policy).
      // In this case, we don't have any active accounts available on the msal/authorization instance.
      // Let's just trigger the logout for this user, so they have to re-login with the sign-in policy that has more information.
      if (idTokenClaims.isForgotPassword) {
        instance.logoutRedirect();
        return;
      }

      // When the policy is sign-in, we want to set everything in the cache to the response values.
      if (idTokenClaims.tfp === b2cPolicies.names.signUpAndSignIn) {
        instance.setActiveAccount(response.account);
        setAuthInformation(
          await getAuthInformation(response.account?.name, response.account?.username, response.idTokenClaims)
        );
        return;
      }

      // When the policy is edit-profile, it doesn't contain our extra claims (enrichment only works for the sign-in policy).
      // In this case, we want to set the active account and most of the auth information from the account we initially signed in with.
      // However, we should set the account name to the response values (so we get the updated name).
      if (idTokenClaims.tfp === b2cPolicies.names.profileUpdate) {
        const signInAccount: AccountInfo | null = getSignInAccount();
        instance.setActiveAccount(signInAccount);
        setAuthInformation(
          await getAuthInformation(response.account?.name, signInAccount?.username, signInAccount?.idTokenClaims)
        );
        return;
      }
    }

    // We cancelled an authentication or refreshed the page or redirected back to this site or something went wrong.
    // In this case, we want to set everything to the account we initially signed in with.
    // However, we might have done an edit-profile as well, so let's set the account name to the last authenticated account
    const signInAccount: AccountInfo | null = getSignInAccount();
    const lastAccount: AccountInfo = getLastAccount();
    instance.setActiveAccount(signInAccount);
    setAuthInformation(
      await getAuthInformation(lastAccount.name, signInAccount?.username, signInAccount?.idTokenClaims)
    );
  }

  async function getAuthInformation(
    accountName?: string,
    accountUsername?: string,
    idTokenClaims?: IdTokenClaims
  ): Promise<AuthInformation> {
    let isEclicCommunityAdmin: boolean = false;
    const roles: Role[] | null = idTokenClaims?.extension_Roles
      ? idTokenClaims.extension_Roles.split(",").map((r: string) => {
          const roleSplit = r.split(".");
          const role = { domain: roleSplit[0], role: roleSplit[1], object: roleSplit[2] };
          if (isUserEclicCommunityAdmin(role)) {
            isEclicCommunityAdmin = true;
            return { ...role, role: "EclicCommunityAdmin" };
          } else {
            return role;
          }
        })
      : null;

    const userRoles: string[] = [];

    if (isExtraEecdAuthorizationRequired()) {
      if (await isUserCommunityAdminAsync()) {
        userRoles.push("CommunityAdmin");
      }
      if (await isEecdCompanyAdminAsync()) {
        userRoles.push("EecdCompanyAdmin");
      }
      if (await isUserAdministratorAsync()) {
        userRoles.push("UserAdministrator");
      }
    }

    const authInformation: AuthInformation = {
      objectId: idTokenClaims?.oid,
      accountName: accountName ?? "Profile",
      emailAddress: accountUsername,
      ninaRoles: idTokenClaims?.extension_NiNaroles?.split(","),
      ninaUserDefaultCompany: idTokenClaims?.extension_NiNaUserDefaultCompany,
      roles: roles,
      userRoles: userRoles,
      isInEclicCommunity: false,
      isEclicCommunityAdmin,
    };

    if (isExtraEecdAuthorizationRequired()) {
      authInformation.isInEclicCommunity = await isUserInEclicCommunityAsync();
    }

    return authInformation;
  }

  function getLastAccount(): AccountInfo {
    return instance.getAllAccounts().reduce((previousAccount: AccountInfo, currentAccount: AccountInfo) => {
      const previousTokenClaims: IdTokenClaims | undefined = previousAccount.idTokenClaims;
      const currentTokenClaims: IdTokenClaims | undefined = currentAccount.idTokenClaims;
      if (
        previousTokenClaims &&
        currentTokenClaims &&
        previousTokenClaims.auth_time &&
        currentTokenClaims.auth_time
      ) {
        return currentTokenClaims.auth_time > previousTokenClaims.auth_time ? currentAccount : previousAccount;
      }
      return currentAccount; // Fallback in case we can't extract the claims
    });
  }

  // Should only return false when a path is whitelisted. Will return mostly true.
  function isExtraEecdAuthorizationRequired(): boolean {
    return !authWhitelistPaths.some((path) => matchPath(path, location.pathname));
  }
}
