import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import RouteDefinition from "../../../types/RouteDefinition";
import useAllowedToViewContent from "../../../hooks/useAllowedToViewContent";

type Props = {
  route: RouteDefinition;
  depth?: number;
  selectedRoutePath: string | undefined;
  setSelectedRoutePath(path: string): void;
};

export default function SidebarItem(props: Readonly<Props>): JSX.Element {
  const isAllowedToViewContent = useAllowedToViewContent();
  const { t } = useTranslation();
  const [openListItem, setOpenListItem] = useState(false);
  const [subRoutes] = useState<RouteDefinition[] | undefined>(props.route.children);

  const handleListItemClick = (): void => {
    props.setSelectedRoutePath(props.route.path);
  };

  const handleExpandClick = (): void => {
    setOpenListItem(!openListItem);
  };

  const getListItemId = (): string => {
    return `sidebar-content-list${props.route.path.replaceAll("/", "-")}`;
  };

  return (
    <Box>
      {props.route.showInSidebar && isAllowedToViewContent(props.route) && (
        <Box>
          {subRoutes && subRoutes.length > 0 ? (
            <Box>
              <ListItemButton
                id={`${getListItemId()}-main-button`}
                sx={{ pl: props.depth && 3 + props.depth }}
                onClick={handleExpandClick}
              >
                <ListItemIcon>{props.route.icon}</ListItemIcon>
                <ListItemText
                  primary={props.route.menuTitle ? t(props.route.menuTitle) : t(props.route.title ?? "")}
                />
                {(() => {
                  if (openListItem) {
                    return <ExpandLess />;
                  }
                  return <ExpandMore />;
                })()}
              </ListItemButton>
              <Collapse in={openListItem} timeout="auto" unmountOnExit>
                <List id={`${getListItemId()}`}>
                  {subRoutes.map((subRoute) => (
                    <SidebarItem
                      key={subRoute.path}
                      route={subRoute}
                      depth={props.depth ? props.depth + 1 : 1}
                      setSelectedRoutePath={props.setSelectedRoutePath}
                      selectedRoutePath={props.selectedRoutePath}
                    />
                  ))}
                </List>
              </Collapse>
            </Box>
          ) : (
            <ListItemButton
              id={`${getListItemId()}-button`}
              component={Link}
              to={props.route.path}
              sx={{ pl: props.depth && 3 + props.depth }}
              onClick={handleListItemClick}
              selected={props.route.path === props.selectedRoutePath}
            >
              <ListItemIcon>{props.route.icon}</ListItemIcon>
              <ListItemText
                primary={props.route.menuTitle ? t(props.route.menuTitle) : t(props.route.title ?? "")}
              />
            </ListItemButton>
          )}
        </Box>
      )}
    </Box>
  );
}
