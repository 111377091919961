import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSnackbarStore from "../../../stores/snackbarStore";
import getUserInvitationAsync from "../api/getUserInvitationAsync";
import updateUserInvitationAsync from "../api/updateUserInvitationAsync";
import UserInvitationResponse from "../types/UserInvitationResponse";
import UpdateUserInvitationRequest from "../types/UpdateUserInvitationRequest";
import UserInvitationForm from "../components/UserInvitationForm";

export default function UpdateUserInvitation(): JSX.Element {
  const id: string = useParams().id as string;
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const [userInvitation, setUserInvitation] = useState<UserInvitationResponse | null>();

  useEffect(() => {
    (async () => {
      if (!userInvitation) {
        setUserInvitation(await getUserInvitationAsync(id));
      }
    })();
  }, [userInvitation, id]);

  async function submitFormAsync(request: UpdateUserInvitationRequest, redirectPath: string): Promise<void> {
    const response = await updateUserInvitationAsync(id, request);
    if (response) {
      useSnackbarStore.getState().addSuccess(t("successfully-edited-user-invitation"));
      navigate(redirectPath);
    }
  }

  if (userInvitation === undefined) {
    return <LinearProgress id="update-user-invitation-loading" />;
  }

  if (userInvitation === null) {
    return <Typography id="update-user-invitation-not-found">{t("user-invitation-not-found")}</Typography>;
  }

  return <UserInvitationForm userInvitation={userInvitation} submitFormAsync={submitFormAsync} />;
}
