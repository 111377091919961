import { useIsAuthenticated } from "@azure/msal-react";
import RouteDefinition from "../types/RouteDefinition";
import useAuthStore from "../stores/authStore";
import companyDataStore from "../stores/companyDataStore";

export default function useAllowedToViewContent() {
  const isAuthenticated: boolean = useIsAuthenticated();
  const { authInformation } = useAuthStore();
  const { companyData } = companyDataStore();

  function isAllowedToViewContent(route: RouteDefinition): boolean {
    if (!route.allowedRoles) return true;

    if (!authInformation?.ninaRoles || !isAuthenticated) return false;

    if (!authInformation?.isInEclicCommunity) return false;

    if (!route.myEecdRoute && !companyData) return false;

    const matchNinaRole = authInformation?.ninaRoles.some((role) => route.allowedRoles?.indexOf(role) !== -1);
    const matchRole = authInformation?.roles?.some((role) =>
      route.allowedRoles?.some((allowedRole) => allowedRole === `${role.domain}.${role.role}`)
    );
    const matchUserRole = authInformation?.userRoles?.some((role) => route.allowedRoles?.indexOf(role) !== -1);

    return matchNinaRole || !!matchRole || !!matchUserRole;
  }

  return isAllowedToViewContent;
}
