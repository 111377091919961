import { AccountInfo } from "@azure/msal-browser";
import authInstance from "../lib/authInstance";
import { b2cPolicies } from "../config/authConfig";
import IdTokenClaims from "../types/IdTokenClaims";

// We support different policies (flows) from msal (AAD B2C).
// Use this function to select the account from sign-in, which is the only policy that contains our extra claims (enrichment only works for the sign-in policy).
export default function getSignInAccount(): AccountInfo | null {
  // Select the account(s) from the sign-in policy
  const signInAccounts: AccountInfo[] = authInstance.getAllAccounts().filter((account: AccountInfo) => {
    const accountTokenClaims: IdTokenClaims | undefined = account.idTokenClaims;
    if (accountTokenClaims && accountTokenClaims.tfp === b2cPolicies.names.signUpAndSignIn) {
      return account;
    }
  });

  let signInAccount: AccountInfo;
  if (signInAccounts.length > 1) {
    // Select the account that was authenticated last (happens when a session is timed-out and they login again)
    signInAccount = signInAccounts.sort((current: AccountInfo, next: AccountInfo) => {
      const currentAccountTokenClaims: IdTokenClaims | undefined = current.idTokenClaims;
      const nextAccountTokenClaims: IdTokenClaims | undefined = next.idTokenClaims;
      if (nextAccountTokenClaims?.auth_time && currentAccountTokenClaims?.auth_time) {
        return nextAccountTokenClaims.auth_time - currentAccountTokenClaims.auth_time; // Sort desc by auth_time (when the user was authenticated)
      } else {
        return 0; // Fallback / keep original order
      }
    })[0];
  } else {
    signInAccount = signInAccounts[0];
  }

  return signInAccount ?? null; // Let's swap undefined with null (msal likes this more)
}
