import { nanoid } from "nanoid";
import create, { SetState, StoreApi, UseBoundStore } from "zustand";
import SnackbarType from "../types/SnackbarType";

interface Snackbar {
  id: string;
  type: SnackbarType;
  text: string;
}

interface SnackbarStore {
  snackbar: Snackbar | null;
  addInfo: (text: string) => void;
  addSuccess: (text: string) => void;
  addWarning: (text: string) => void;
  addError: (text: string) => void;
  dismiss: () => void;
}

function add(set: SetState<SnackbarStore>, text: string, type: SnackbarType): void {
  set(() => ({
    snackbar: {
      id: nanoid(),
      type: type,
      text: text,
    },
  }));
}

const snackbarStore: UseBoundStore<StoreApi<SnackbarStore>> = create<SnackbarStore>(
  (set: SetState<SnackbarStore>) => ({
    snackbar: null,

    addInfo: (text: string) => add(set, text, SnackbarType.info),
    addSuccess: (text: string) => add(set, text, SnackbarType.success),
    addWarning: (text: string) => add(set, text, SnackbarType.warning),
    addError: (text: string) => add(set, text, SnackbarType.error),

    dismiss: () => {
      set(() => ({
        snackbar: null,
      }));
    },
  })
);

export default snackbarStore;
