import bffApi from "../../../lib/bffApi";
import UpdateUserInvitationRequest from "../types/UpdateUserInvitationRequest";

export default async function updateUserInvitationAsync(
  id: string,
  request: UpdateUserInvitationRequest
): Promise<boolean> {
  const response = await bffApi.put(`/userinvitations/${id}`, request);
  return (response as unknown as string) === "";
}
