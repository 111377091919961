import { JSXElementConstructor, ReactElement } from "react";
import { useRoutes } from "react-router-dom";
import { Box, LinearProgress, Toolbar } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import MainContentHeader from "./MainContentHeader";
import Forbidden from "./Forbidden";
import { sidebarWidth } from "../config";
import RouteDefinition from "../../../types/RouteDefinition";
import useAllowedToViewContent from "../../../hooks/useAllowedToViewContent";
import useGetCurrentRoute from "../../../hooks/useGetCurrentRoute";
import useAuthStore from "../../../stores/authStore";
import companyDataStore from "../../../stores/companyDataStore";

type Props = {
  routes: RouteDefinition[];
};

export default function MainContent(props: Readonly<Props>): JSX.Element {
  const isAuthenticated: boolean = useIsAuthenticated();
  const isAllowedToViewContent = useAllowedToViewContent();
  const { companyData } = companyDataStore();
  const currentRoute: RouteDefinition | undefined = useGetCurrentRoute(props.routes);
  const { authInformation } = useAuthStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routes: ReactElement<any, string | JSXElementConstructor<any>> | null = useRoutes(props.routes);

  function displayMainContent(): JSX.Element {
    return (
      <Box>
        {currentRoute && isAllowedToViewContent(currentRoute) ? (
          <Box>
            <MainContentHeader routes={props.routes} />
            {routes}
          </Box>
        ) : (
          <Forbidden />
        )}
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, padding: 3, width: { sm: `calc(100% - ${sidebarWidth}px)` } }}>
      <Toolbar />
      {isAuthenticated && (authInformation === undefined || companyData === undefined) ? (
        <LinearProgress id="main-content-loading" />
      ) : (
        displayMainContent()
      )}
    </Box>
  );
}
