import { BrowserCacheLocation, Configuration, RedirectRequest, SilentRequest } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpAndSignIn: process.env.REACT_APP_B2C_POLICIES_SIGNUPANDSIGNIN,
    profileUpdate: process.env.REACT_APP_B2C_POLICIES_PROFILEUPDATE,
    passwordReset: process.env.REACT_APP_B2C_POLICIES_PASSWORDRESET,
  },
  authorities: {
    signUpAndSignIn: process.env.REACT_APP_AUTH_SIGN_UP_SIGN_IN_AUTHORITY,
    profileUpdate: process.env.REACT_APP_AUTH_EDIT_PROFILE_AUTHORITY,
  },
};

export const authConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    authority: b2cPolicies.authorities.signUpAndSignIn,
    knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITY as string],
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export const protectedResources = {
  bff: {
    endpoint: process.env.REACT_APP_BFF_URI as string,
    scopes: (process.env.REACT_APP_AUTH_BFF_SCOPES as string).split(" "),
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [...protectedResources.bff.scopes],
};

export const editProfileRequest: RedirectRequest = {
  scopes: [...loginRequest.scopes],
  authority: b2cPolicies.authorities.profileUpdate,
};

export const bffRequest: SilentRequest = {
  scopes: [...protectedResources.bff.scopes],
};

export const authWhitelistPaths: string[] = ["/user-invitations/:id"];
