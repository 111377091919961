import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Forbidden(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Typography id="forbidden-title" variant="h3" color="secondary">
      {t("no-permission")}
    </Typography>
  );
}
