import { useEffect, useState } from "react";
import { useLocation, Location } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import { maxLengthValidationMessage, requiredValidationMessage } from "../../../config/formValidationMessages";
import getLanguagesAsync from "../../language/api/getLanguagesAsync";
import getTimeZonesAsync from "../../timezone/api/getTimeZonesAsync";
import getRolesByTypeAsync from "../../role/api/getRolesByTypeAsync";
import UserInvitationResponse from "../types/UserInvitationResponse";
import UserInvitationRoleResponse from "../types/UserInvitationRoleResponse";
import CreateUserInvitationRequest from "../types/CreateUserInvitationRequest";
import UpdateUserInvitationRequest from "../types/UpdateUserInvitationRequest";
import LanguageResponse from "../../language/types/LanguageResponse";
import RoleResponse from "../../role/types/RoleResponse";
import UserRoleRequest from "../../user/types/UserRoleRequest";

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

type Props = {
  userInvitation?: UserInvitationResponse | null;
  submitFormAsync(
    request: CreateUserInvitationRequest | UpdateUserInvitationRequest,
    redirectPath: string
  ): Promise<void>;
};

type State = {
  previousPath: string;
};

export default function UserInvitationForm(props: Readonly<Props>): JSX.Element {
  const { t } = useTranslation();
  const location: Location = useLocation();
  const state = location.state as State;
  const [languages, setLanguages] = useState<LanguageResponse[]>();
  const [timezones, setTimezones] = useState<string[]>();
  const [roles, setRoles] = useState<RoleResponse[] | null>();
  const [userRoles, setUserRoles] = useState<UserRoleRequest[]>([]);
  const [originalUserRoles, setOriginalUserRoles] = useState<UserRoleRequest[]>([]);
  const [activeRow, setActiveRow] = useState<GridRowModesModel>({});
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserInvitationRequest | UpdateUserInvitationRequest>({
    defaultValues: {
      invitedEmail: props.userInvitation?.invitedEmail ?? "",
      timezone: props.userInvitation?.timezone ?? "",
      preferredLanguage: props.userInvitation?.preferredLanguage?.code ?? "",
      roles: [], // We use userRoles to manipulate via the grid and later set this form value
    },
  });

  useEffect(() => {
    (async () => {
      if (!languages && !timezones && !roles) {
        setLanguages(await getLanguagesAsync());
        setTimezones(await getTimeZonesAsync());
        setRoles(await getRolesByTypeAsync("User"));

        if (props.userInvitation?.roles) {
          const existingUserRoles: UserRoleRequest[] = props.userInvitation.roles.map(
            (userRole: UserInvitationRoleResponse) => ({
              id: nanoid(),
              roleId: userRole.role.id,
              roleName: userRole.role.name,
              isMainUser: userRole.isMainUser,
              isNew: false,
            })
          );
          setUserRoles(existingUserRoles);
          setOriginalUserRoles(existingUserRoles);
        }
      }
    })();
  }, [languages, timezones, roles, props.userInvitation]);

  const rolesColumns: GridColDef[] = [
    {
      field: "role",
      headerName: t("role"),
      width: 200,
      editable: true,
      type: "singleSelect",
      renderCell: (params) => userRoles.find((userRole: UserRoleRequest) => userRole.id === params.id)?.roleName,
      renderEditCell: (params) => {
        const userRole = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id);
        const filteredRoles =
          roles?.filter(
            (role: RoleResponse) =>
              !userRoles?.some(
                (userRole: UserRoleRequest) => userRole.id !== params.id && role.id === userRole.roleId
              )
          ) ?? [];
        return (
          <Select
            id="user-invitation-form-roles-grid-role-column"
            value={userRole?.roleId}
            fullWidth
            error={!userRole?.roleId}
            onChange={(e) => {
              const role = roles?.find((r: RoleResponse) => r.id === e.target.value);
              if (role) {
                setUserRoles([
                  ...(userRoles?.map((userRole: UserRoleRequest) =>
                    params.id === userRole.id ? { ...userRole, roleId: role.id, roleName: role.name } : userRole
                  ) ?? []),
                ]);
              }
            }}
          >
            {filteredRoles?.map((role: RoleResponse) => {
              return (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
    {
      field: "isMainUser",
      headerName: t("is-main-user"),
      align: "left",
      headerAlign: "left",
      width: 180,
      editable: true,
      renderCell: (params) => {
        const isMainUser = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id)?.isMainUser;
        return <Checkbox checked={isMainUser} disabled={true} />;
      },
      renderEditCell: (params) => {
        const userRole = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id);
        return (
          <Checkbox
            id="user-invitation-form-roles-grid-is-main-user-column"
            checked={userRole?.isMainUser}
            onChange={(e) => {
              setUserRoles(
                userRoles?.map((userRole: UserRoleRequest) =>
                  params.id === userRole.id
                    ? {
                        ...userRole,
                        isMainUser: e.target.checked,
                      }
                    : userRole
                ) ?? []
              );
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      flex: 1,
      align: "right",
      getActions: ({ id }) => {
        const isInEditMode = activeRow[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`}
              id="user-invitation-form-roles-grid-save-button"
              icon={<SaveIcon />}
              label={t("save")}
              onClick={handleRowSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              id="user-invitation-form-roles-grid-cancel-button"
              icon={<CancelIcon />}
              label={t("cancel")}
              onClick={handleRowCancelClick(id)}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={`edit-${id}`}
            id="user-invitation-form-roles-grid-edit-button"
            icon={<EditIcon />}
            label={t("edit")}
            onClick={handleRowEditClick(id)}
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            id="user-invitation-form-roles-grid-delete-button"
            icon={<DeleteIcon />}
            label={t("delete")}
            onClick={handleRowDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  const handleRowSaveClick = (id: GridRowId) => () => {
    setActiveRow({
      ...activeRow,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleRowEditClick = (id: GridRowId) => () => {
    setActiveRow({
      ...activeRow,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleRowCancelClick = (id: GridRowId) => () => {
    setActiveRow({
      ...activeRow,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (userRoles?.find((userRole: UserRoleRequest) => userRole.id === id)?.isNew) {
      setUserRoles(userRoles?.filter((userRole: UserRoleRequest) => userRole.id !== id));
    } else {
      const originalUserRole = originalUserRoles.find((u: UserRoleRequest) => u.id === id);
      if (originalUserRole) {
        setUserRoles([
          ...userRoles.map((userRole: UserRoleRequest) => (userRole.id === id ? originalUserRole : userRole)),
        ]);
      }
    }
  };

  const handleRowDeleteClick = (id: GridRowId) => () => {
    setUserRoles(userRoles?.filter((userRole: UserRoleRequest) => userRole.id !== id));
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // For some reason, the newRow (_) has not updated the isMainUser. So let's use the oldRow (row)
  const processRowUpdate = (_: GridRowModel, row: GridRowModel) => {
    const updatedRow: UserRoleRequest = {
      ...row,
      id: row.id,
      roleId: row.roleId,
      roleName: row.roleName,
      isMainUser: row.isMainUser,
      isNew: false,
    };
    const userRole = userRoles.map((userRole) => (userRole.id === row.id ? updatedRow : userRole));
    setUserRoles(userRole);
    setOriginalUserRoles(userRole);
    return updatedRow;
  };

  function userRolesGridToolbar() {
    const handleClick = () => {
      const tempId = nanoid();
      const newRole: UserRoleRequest = {
        id: tempId,
        roleId: "",
        roleName: "",
        isMainUser: false,
        isNew: true,
      };
      if (userRoles) {
        setUserRoles([...userRoles, newRole]);
      }
      setActiveRow({
        ...activeRow,
        [tempId]: { mode: GridRowModes.Edit, fieldToFocus: "role" },
      });
    };

    return (
      <GridToolbarContainer>
        <Button
          id="user-invitation-form-roles-grid-add-button"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          {t("add-user-role")}
        </Button>
      </GridToolbarContainer>
    );
  }

  async function submitFormAsync(
    request: CreateUserInvitationRequest | UpdateUserInvitationRequest
  ): Promise<void> {
    request.roles = userRoles?.map((userRole: UserRoleRequest) => ({
      id: userRole.id,
      isMainUser: userRole.isMainUser,
      roleId: userRole.roleId,
    }));
    await props.submitFormAsync(request, state?.previousPath ?? "/user-invitations");
  }

  if (languages === undefined || timezones === undefined || roles === undefined) {
    return <LinearProgress id="user-invitation-form-loading" />;
  }

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="user-invitation-form"
      onSubmit={handleSubmit(submitFormAsync)}
      autoComplete="off"
    >
      <Grid item xs={12}>
        <Controller
          control={control}
          name="invitedEmail"
          render={({ field }) => (
            <StyledTextField
              {...field}
              id="user-invitation-form-invited-email"
              label={t("invited-email")}
              disabled={!!props.userInvitation}
              error={!!errors.invitedEmail}
              helperText={t(errors.invitedEmail?.message ?? "")}
              fullWidth
            />
          )}
          rules={{ required: requiredValidationMessage, maxLength: maxLengthValidationMessage(320) }}
        />
        <Controller
          control={control}
          name="preferredLanguage"
          render={() => (
            <FormControl fullWidth>
              <InputLabel id="user-invitation-form-preferred-language-select-label">
                {t("preferred-language")}
              </InputLabel>
              <Select
                sx={{ mb: 2 }}
                id="user-invitation-form-preferred-language-select"
                labelId="user-invitation-form-preferred-language-select-label"
                label={t("preferred-language")}
                value={getValues("preferredLanguage")}
                onChange={(event) => {
                  setValue("preferredLanguage", event.target.value);
                }}
              >
                {languages.map((language: LanguageResponse) => {
                  return (
                    <MenuItem key={language.code} value={language.code} sx={{ minHeight: "36px!important" }}>
                      {language.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="timezone"
          render={() => (
            <FormControl fullWidth>
              <InputLabel id="user-invitation-form-timezone-select-label">{t("time-zone")}</InputLabel>
              <Select
                sx={{ mb: 2 }}
                id="user-invitation-form-timezone-select"
                labelId="user-invitation-form-timezone-select-label"
                label={t("time-zone")}
                value={getValues("timezone")}
                onChange={(event) => {
                  setValue("timezone", event.target.value);
                }}
              >
                {timezones?.map((timezone: string) => {
                  return (
                    <MenuItem key={timezone} value={timezone} sx={{ minHeight: "36px!important" }}>
                      {timezone}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />
        <Typography id="user-invitation-form-roles-title" variant="h5">
          {t("user-roles")}
        </Typography>
        <Box sx={{ mt: 2, mb: 3, height: 325, width: "100%" }} id="user-invitation-form-roles-grid">
          <DataGrid
            columns={rolesColumns}
            rows={userRoles}
            editMode="row"
            rowModesModel={activeRow}
            onRowModesModelChange={(newModel: GridRowModesModel) => setActiveRow(newModel)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: userRolesGridToolbar,
            }}
            slotProps={{
              toolbar: { setUserRoles, setActiveRow },
            }}
          />
        </Box>
        <LoadingButton
          id="user-invitation-form-save-button"
          loading={isSubmitting}
          variant="contained"
          type="submit"
        >
          {t("save")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
