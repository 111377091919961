import LoggingIn from "./LoggingIn";
import LogoutCallback from "./LogoutCallback";
import RouteDefinition from "../../../types/RouteDefinition";
import Forbidden from "../components/Forbidden";

export const routes: RouteDefinition[] = [
  {
    path: "/signin-oidc",
    element: <LoggingIn />,
    showInSidebar: false,
  },
  {
    path: "/signout-callback-oidc",
    element: <LogoutCallback />,
    showInSidebar: false,
  },
  {
    path: "/forbidden",
    element: <Forbidden />,
    showInSidebar: false,
  },
];
