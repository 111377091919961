import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserInvitationForm from "../components/UserInvitationForm";
import useSnackbarStore from "../../../stores/snackbarStore";
import createUserInvitationAsync from "../api/createUserInvitationAsync";
import CreateUserInvitationRequest from "../types/CreateUserInvitationRequest";

export default function CreateUserInvitation(): JSX.Element {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  async function submitFormAsync(request: CreateUserInvitationRequest, redirectPath: string): Promise<void> {
    const response = await createUserInvitationAsync(request);
    if (response) {
      useSnackbarStore.getState().addSuccess(t("successfully-created-user-invitation"));
      navigate(redirectPath);
    }
  }

  return <UserInvitationForm submitFormAsync={submitFormAsync} />;
}
