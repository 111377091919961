import { userAdministrator } from "../../../config/roles";
import RouteDefinition from "../../../types/RouteDefinition";
import CreateUserInvitation from "./CreateUserInvitation";
import UpdateUserInvitation from "./UpdateUserInvitation";
import UserInvitation from "./UserInvitation";
import UserInvitationsOverview from "./UserInvitationsOverview";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

export const routes: RouteDefinition[] = [
  {
    path: "/user-invitations",
    element: <UserInvitationsOverview />,
    title: "user-invitations",
    icon: <GroupAddIcon id="userInvitationsIcon" />,
    showInSidebar: true,
    allowedRoles: [userAdministrator],
  },
  {
    path: "/user-invitations/create",
    element: <CreateUserInvitation />,
    title: "create-user-invitation",
    showInSidebar: false,
    allowedRoles: [userAdministrator],
    headerButton: {
      content: "user-invitations",
      path: "/user-invitations",
    },
  },
  {
    path: "/user-invitations/:id/edit",
    element: <UpdateUserInvitation />,
    title: "edit-user-invitation",
    showInSidebar: false,
    allowedRoles: [userAdministrator],
    headerButton: {
      content: "user-invitations",
      path: "/user-invitations",
    },
  },
  {
    path: "/user-invitations/:id",
    element: <UserInvitation />,
    title: "user-invitation",
    showInSidebar: false,
  },
];
