import NotFound from "../features/layout/components/NotFound";
import { routes as layoutRoutes } from "../features/layout/routes";
import { routes as homepageRoutes } from "../features/homepage/routes";
import { routes as eecdRoutes } from "../features/eecd/routes";
import { routes as userRoutes } from "../features/user/routes";
import { routes as companyRoutes } from "../features/company/routes";
import { routes as communityRoutes } from "../features/community/routes";
import { routes as userInvitationRoutes } from "../features/user-invitation/routes";
import RouteDefinition from "../types/RouteDefinition";

const routes: RouteDefinition[] = [
  ...layoutRoutes,
  ...homepageRoutes,
  ...eecdRoutes,
  ...userRoutes,
  ...companyRoutes,
  ...communityRoutes,
  ...userInvitationRoutes,
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
