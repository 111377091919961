import { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { AppBar, Box, IconButton, MenuItem, Select, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useAuthStore from "../../../stores/authStore";
import CompanyLogoLink from "./CompanyLogoLink";
import LoginProfileContextButton from "./LoginProfileContextButton";
import getMyLanguageAsync from "../../language/api/getMyLanguageAsync";
import upsertUserLanguageAsync from "../../language/api/upsertUserLanguageAsync";
import IsoLanguage from "../../../types/IsoLanguage";
import { sidebarWidth } from "../config";
import { isoLanguages } from "../../../config/isoLanguages";

type Props = {
  onClickSidebarToggle(): void;
};

export default function TopBar(props: Readonly<Props>): JSX.Element {
  const isAuthenticated: boolean = useIsAuthenticated();
  const { i18n } = useTranslation();
  const { authInformation } = useAuthStore();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && authInformation) {
        const myLanguageResponse = await getMyLanguageAsync();
        if (myLanguageResponse) {
          const language = isoLanguages.find((c: IsoLanguage) => c.code === myLanguageResponse.code);
          if (language) {
            i18n.changeLanguage(language.code);
          }
        }
      }
    })();
  }, [i18n, isAuthenticated, authInformation]);

  async function handleChangeUserLanguage(code: string): Promise<void> {
    const language = isoLanguages.find((c: IsoLanguage) => c.code === code);
    if (language) {
      i18n.changeLanguage(language.code);

      if (isAuthenticated && authInformation) {
        await upsertUserLanguageAsync(language.code);
      }
    }
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${sidebarWidth}px)` },
        ml: { sm: `${sidebarWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          id="topBar-menu-button"
          onClick={props.onClickSidebarToggle}
          edge="start"
          sx={{ mr: 2, display: { sm: "none" } }}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Box id="topBar-company-logo" sx={{ flexGrow: 1 }}>
          <CompanyLogoLink />
        </Box>

        <Box sx={{ mr: 2 }}>
          <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            id="language-select"
            value={i18n.language}
            fullWidth
            onChange={(e) => {
              (async () => {
                await handleChangeUserLanguage(e.target.value);
              })();
            }}
          >
            {isoLanguages.map((language: IsoLanguage) => (
              <MenuItem key={language.code} value={language.code}>
                <img src={`/images/flags/${language.code}.png`} alt={language.code} />
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box id="topBar-login-profile-context-button">
          <LoginProfileContextButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
