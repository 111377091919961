import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationsEn from "./translations/en-gb.json";
import translationsNl from "./translations/nl-be.json";
import translationsFr from "./translations/fr-be.json";
import translationsDe from "./translations/de.json";
import translationsEs from "./translations/es.json";
import translationsIt from "./translations/it.json";

const resources = {
  en: {
    translation: { ...translationsEn },
  },
  nl: {
    translation: { ...translationsNl },
  },
  fr: {
    translation: { ...translationsFr },
  },
  de: {
    translation: { ...translationsDe },
  },
  es: {
    translation: { ...translationsEs },
  },
  it: {
    translation: { ...translationsIt },
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "en",
    lowerCaseLng: true,
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      convertDetectedLanguage: (lng: string) => lng.replace("_", "-"),
    },
  });

export default i18n;
