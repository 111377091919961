import bffApi from "../../../lib/bffApi";
import CreateUserRoleRequest from "../types/CreateUserRoleRequest";
import UserRoleResponse from "../../user/types/UserRoleResponse";

export async function createUserRoleAsync(
  userObjectId: string,
  request: CreateUserRoleRequest
): Promise<UserRoleResponse> {
  return bffApi.post(`users/${userObjectId}/userroles`, request);
}
