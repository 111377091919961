import bffApi from "../../../lib/bffApi";
import UpdateUserRoleRequest from "../types/UpdateUserRoleRequest";

export async function updateUserRoleAsync(
  userObjectId: string,
  id: string,
  request: UpdateUserRoleRequest
): Promise<boolean> {
  const response = await bffApi.put(`/users/${userObjectId}/userroles/${id}`, request);
  return (response as unknown as string) === "";
}
