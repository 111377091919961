import { useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Button, Menu, MenuItem, PopoverOrigin } from "@mui/material";
import { useTranslation } from "react-i18next";
import getLoginRequest from "../../../utils/getLoginReqest";
import useAuthStore from "../../../stores/authStore";

export default function LoginProfileContextButton(): JSX.Element {
  const { t } = useTranslation();
  const isAuthenticated: boolean = useIsAuthenticated();
  const { instance } = useMsal();
  const { authInformation } = useAuthStore();

  const [profileContextAnchorElement, setProfileContextAnchorElement] = useState<Element | null>(null);
  const isProfileContextMenuOpen = Boolean(profileContextAnchorElement);
  const profileContextAnchorOrigin: PopoverOrigin = { horizontal: "right", vertical: "bottom" };

  function onClickLoginRegisterButton() {
    instance.loginRedirect(getLoginRequest());
  }

  function onOpenProfileContextMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    setProfileContextAnchorElement(event.currentTarget);
  }

  function onCloseProfileContextMenu(): void {
    setProfileContextAnchorElement(null);
  }

  return isAuthenticated ? (
    <Box>
      <Button id="profile-button" onClick={onOpenProfileContextMenu} color="inherit">
        {authInformation?.accountName}
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={profileContextAnchorElement}
        open={isProfileContextMenuOpen}
        onClose={onCloseProfileContextMenu}
        anchorOrigin={profileContextAnchorOrigin}
      >
        <MenuItem id="logout-button" onClick={() => instance.logoutRedirect()}>
          {t("logout")}
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <Button id="login-register-button" onClick={onClickLoginRegisterButton} color="inherit">
      {t("login")} / {t("register")}
    </Button>
  );
}
