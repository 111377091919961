import { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import companyDataStore from "../stores/companyDataStore";
import getCompanyDataAsync from "../features/company/api/getCompanyDataAsync";
import useAuthStore from "../stores/authStore";

export default function useGetCompanyData() {
  const { authInformation } = useAuthStore();
  const isAuthenticated: boolean = useIsAuthenticated();
  const { setCompanyData, companyData } = companyDataStore();

  useEffect(() => {
    (async () => {
      if (companyData === undefined && isAuthenticated && authInformation) {
        setCompanyData(authInformation.isInEclicCommunity ? await getCompanyDataAsync() : null);
      }
    })();
  }, [companyData, setCompanyData, isAuthenticated, authInformation]);
}
