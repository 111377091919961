import AuthInformation from "../types/AuthInformation";
import Role from "../types/Role";

export function isUserCompanyAdmin(authInformation: AuthInformation | null | undefined): boolean | undefined {
  return authInformation?.roles?.some(
    (role: Role) =>
      role.domain === "Customers" &&
      role.role === "CompanyAdmin" &&
      role.object === authInformation?.ninaUserDefaultCompany
  );
}
