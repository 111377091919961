import { useState } from "react";
import { Box } from "@mui/material";
import TopBar from "./features/layout/components/TopBar";
import Sidebar from "./features/layout/components/Sidebar";
import MainContent from "./features/layout/components/MainContent";
import Snackbar from "./features/layout/components/Snackbar";
import routes from "./routes";
import useConfigureAuthCache from "./hooks/useConfigureAuthCache";
import useGetCompanyData from "./hooks/useGetCompanyData";

export default function App(): JSX.Element {
  useConfigureAuthCache();
  useGetCompanyData();

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  function onClickSidebarToggle(): void {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <TopBar onClickSidebarToggle={onClickSidebarToggle} />
      <Sidebar isSidebarOpen={isSidebarOpen} onClickSidebarToggle={onClickSidebarToggle} routes={routes} />
      <MainContent routes={routes} />
      <Snackbar />
    </Box>
  );
}
