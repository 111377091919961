import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { requiredValidationMessage } from "../../../config/formValidationMessages";
import CommunityUserRolesResponse from "../types/CommunityUserRolesResponse";
import CompanyResponse from "../../company/types/CompanyResponse";
import { useCallback, useEffect, useState } from "react";
import CompanyUserInformationResponse from "../../user/types/CompanyUserInformationResponse";
import UpsertCommunityUserRolesRequest from "../types/UpsertCommunityUserRolesRequest";
import getUsersInCompanyAsync from "../../user/api/getUsersInCompanyAsync";

type Props = {
  user: CommunityUserRolesResponse | null | undefined;
  users: CommunityUserRolesResponse[] | null | undefined;
  companies: CompanyResponse[] | null | undefined;
  assignCommunityUserRolesDialogOpen: boolean;
  handleCloseAssignCommunityUserRolesDialog(request?: UpsertCommunityUserRolesRequest | null): Promise<void>;
};

export default function AssignCommunityUserRolesDialog(props: Readonly<Props>): JSX.Element {
  const { t } = useTranslation();
  const [companyUsers, setCompanyUsers] = useState<CompanyUserInformationResponse[] | null>();
  const [dialogLoading, setDialogLoading] = useState<boolean>();

  const { user, users, companies, assignCommunityUserRolesDialogOpen, handleCloseAssignCommunityUserRolesDialog } =
    props;

  const {
    control,
    reset,
    setValue,
    getValues,
    resetField,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpsertCommunityUserRolesRequest>({
    defaultValues: {
      companyId: "",
      userObjectId: "",
      userName: "",
      isCommunityAdmin: false,
      isCommunityEquipmentMasterDataAdmin: false,
    },
  });

  const getCompanyUsers = useCallback(
    (companyId: string) => {
      (async () => {
        setDialogLoading(true);
        const companyUsersResponse = await getUsersInCompanyAsync(companyId);
        setCompanyUsers(
          user
            ? companyUsersResponse
            : companyUsersResponse?.filter(
                (cu: CompanyUserInformationResponse) =>
                  !users?.map((u: CommunityUserRolesResponse) => u.objectId)?.includes(cu.objectId)
              )
        );
        setDialogLoading(false);
      })();
    },
    [user, users]
  );

  const resetForm = useCallback(() => {
    reset({
      companyId: user?.companyId ?? "",
      userObjectId: user?.objectId ?? "",
      isCommunityAdmin: user?.isCommunityAdmin ?? false,
      isCommunityEquipmentMasterDataAdmin: user?.isCommunityEquipmentMasterDataAdmin ?? false,
    });
  }, [reset, user?.companyId, user?.objectId, user?.isCommunityAdmin, user?.isCommunityEquipmentMasterDataAdmin]);

  useEffect(() => {
    resetForm();
    if (user) {
      getCompanyUsers(user.companyId);
    }
  }, [user, reset, resetForm, getCompanyUsers]);

  function submitForm(request: UpsertCommunityUserRolesRequest): void {
    (async () => {
      setDialogLoading(true);
      resetForm();
      await handleCloseAssignCommunityUserRolesDialog(request);
      setDialogLoading(false);
    })();
  }

  return (
    <Dialog
      open={assignCommunityUserRolesDialogOpen}
      onClose={() => {
        resetForm();
        handleCloseAssignCommunityUserRolesDialog();
      }}
      PaperProps={{
        sx: {
          overflowY: "visible",
        },
      }}
    >
      <DialogTitle>{t("assign-community-roles")}</DialogTitle>
      <DialogContent sx={{ overflow: "unset" }}>
        {companies ? (
          <Grid
            container
            spacing={2}
            component="form"
            id="assign-community-user-roles-form"
            onSubmit={handleSubmit(submitForm)}
            autoComplete="off"
          >
            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyId"
                render={() => (
                  <FormControl fullWidth>
                    <InputLabel id="assign-community-user-roles-form-company-id-select-label">
                      {t("companies")}
                    </InputLabel>
                    <Select
                      fullWidth
                      disabled={user != null}
                      value={getValues("companyId")}
                      id="assign-community-user-roles-form-company-id-select"
                      labelId="assign-community-user-roles-form-company-id-select-label"
                      label={t("companies")}
                      error={!!errors.companyId}
                      onChange={(event) => {
                        if (event.target.value) {
                          getCompanyUsers(event.target.value);
                          setValue("companyId", event.target.value);
                          clearErrors("companyId");
                        } else {
                          resetField("companyId");
                          resetField("userObjectId");
                        }
                      }}
                    >
                      {companies?.map((c: CompanyResponse) => {
                        return (
                          <MenuItem key={c.id} value={c.id} sx={{ minHeight: "36px!important" }}>
                            {c.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.companyId?.message && (
                      <FormHelperText sx={{ color: "red" }}>{t(errors.companyId.message)}</FormHelperText>
                    )}
                  </FormControl>
                )}
                rules={{
                  required: requiredValidationMessage,
                }}
              />
            </Grid>
            {companyUsers && getValues("companyId") && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="userObjectId"
                  render={() => (
                    <FormControl fullWidth>
                      <InputLabel id="assign-community-user-roles-form-user-select-label">
                        {t("company-users")}
                      </InputLabel>
                      <Select
                        fullWidth
                        disabled={user != null}
                        id="assign-community-user-roles-form-user-select"
                        labelId="assign-community-user-roles-form-user-select-label"
                        label={t("company-users")}
                        value={getValues("userObjectId")}
                        error={!!errors.userObjectId}
                        onChange={(event) => {
                          if (event.target.value) {
                            const user = companyUsers.find((c) => c.objectId === event.target.value);
                            setValue("userObjectId", event.target.value);
                            setValue("userName", user?.name ?? "");
                            clearErrors("userObjectId");
                          } else {
                            resetField("userObjectId");
                            resetField("userName");
                          }
                        }}
                      >
                        {companyUsers?.map((c: CompanyUserInformationResponse) => {
                          return (
                            <MenuItem key={c.objectId} value={c.objectId} sx={{ minHeight: "36px!important" }}>
                              {c.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.userObjectId?.message && (
                        <FormHelperText sx={{ color: "red" }}>{t(errors.userObjectId.message)}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  rules={{
                    required: requiredValidationMessage,
                  }}
                />
              </Grid>
            )}
            {getValues("companyId") && getValues("userObjectId") && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="isCommunityAdmin"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={getValues("isCommunityAdmin")}
                          onChange={(_, value) => {
                            setValue("isCommunityAdmin", value);
                          }}
                          sx={{ mb: 1 }}
                        />
                      }
                      id="assign-community-user-roles-form-is-community-admin"
                      label={t("is-community-admin")}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="isCommunityEquipmentMasterDataAdmin"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={getValues("isCommunityEquipmentMasterDataAdmin")}
                          onChange={(_, value) => {
                            setValue("isCommunityEquipmentMasterDataAdmin", value);
                          }}
                          sx={{ mb: 1 }}
                        />
                      }
                      id="assign-community-user-roles-form-is-community-equipment-master-data-admin"
                      label={t("is-community-equipment-master-data-admin")}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <LoadingButton
                id="assign-community-user-roles-form-submit"
                type="submit"
                loading={isSubmitting || dialogLoading}
                variant="contained"
                sx={{ ml: 1 }}
                style={{ float: "right" }}
              >
                {t("ok")}
              </LoadingButton>
              <Button
                onClick={() => {
                  resetForm();
                  handleCloseAssignCommunityUserRolesDialog();
                }}
                style={{ float: "right" }}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <LinearProgress id="assign-community-user-roles-loading" />
        )}
      </DialogContent>
    </Dialog>
  );
}
