import RouteDefinition from "../../../types/RouteDefinition";
import { companyAdmin, eecdCompanyAdmin } from "../../../config/roles";
import UpdateCompanyData from "./UpdateCompanyData";
import UpdateCompanyAdmins from "./UpdateCompanyAdmins";

export const routes: RouteDefinition[] = [
  {
    path: "/companydata/edit",
    element: <UpdateCompanyData />,
    title: "update-company-data",
    allowedRoles: [companyAdmin],
    headerButton: {
      content: "my-eecd",
      path: "/my-eecd",
    },
  },
  {
    path: "/companydata/admins/edit",
    element: <UpdateCompanyAdmins />,
    title: "update-company-admins",
    allowedRoles: [eecdCompanyAdmin],
    headerButton: {
      content: "my-eecd",
      path: "/my-eecd",
    },
  },
];
