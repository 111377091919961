import { Box, Button, LinearProgress, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {
  GridColDef,
  GridRowParams,
  GridRowModes,
  GridActionsCellItem,
  DataGrid,
  GridRowModesModel,
  GridEventListener,
  MuiEvent,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import useAuthStore from "../../../stores/authStore";
import useSnackbarStore from "../../../stores/snackbarStore";
import UserResponse from "../../user/types/UserResponse";
import getMyUsersAsync from "../../user/api/getMyUsersAsync";
import createCompanyAdminAsync from "../api/createCompanyAdminAsync";
import companyDataStore from "../../../stores/companyDataStore";
import CompanyAdminRequest from "../types/CompanyAdminRequest";
import CompanyUserInformationResponse from "../../user/types/CompanyUserInformationResponse";
import { deleteCompanyAdminAsync } from "../api/deleteCompanyAdminAsync";

export default function UpdateCompanyAdmins(): JSX.Element {
  const { t } = useTranslation();
  const { companyData, setCompanyData } = companyDataStore();
  const { authInformation } = useAuthStore();
  const [users, setUsers] = useState<UserResponse[]>();
  const [companyAdmins, setCompanyAdmins] = useState<CompanyAdminRequest[]>();
  const [companyAdminsActiveRow, setCompanyAdminsActiveRow] = useState<GridRowModesModel>({});

  const companyAdminsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("user"),
      editable: true,
      flex: 1,
      renderCell: (params) => companyAdmins?.find((c: CompanyAdminRequest) => c.id === params.id)?.name,
      renderEditCell: (params) => {
        if (users) {
          const companyAdmin = companyAdmins?.find((c: CompanyAdminRequest) => c.id === params.id);
          const filteredUsers = users.filter(
            (u: UserResponse) =>
              !companyAdmins?.some((c: CompanyAdminRequest) => c.id !== params.id && u.objectId === c.objectId)
          );
          return (
            <Select
              fullWidth
              id="update-company-admin-user-select"
              value={companyAdmin?.objectId}
              onChange={async (event) => {
                const user = users.find((u: UserResponse) => u.objectId === event.target.value);
                setCompanyAdmins(
                  companyAdmins?.map((c: CompanyAdminRequest) =>
                    c.id === params.id ? { ...c, objectId: user?.objectId ?? "", name: user?.name ?? "" } : c
                  )
                );
              }}
            >
              {filteredUsers?.map((u: UserResponse) => {
                return (
                  <MenuItem key={u.objectId} value={u.objectId}>
                    {u.name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }
      },
    },
    {
      field: "options",
      headerName: "",
      type: "actions",
      flex: 1,
      align: "right",
      getActions: (params: GridRowParams) => {
        const isInEditMode = companyAdminsActiveRow[params.id.toString()]?.mode === GridRowModes.Edit;
        const companyAdmin = companyAdmins?.find((c: CompanyAdminRequest) => c.id === params.id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={params.id.toString()}
              icon={<SaveIcon />}
              id="update-company-admin-save-button"
              label={t("save")}
              disabled={companyAdmin && !companyAdmin.objectId}
              onClick={() => {
                if (companyAdmin) {
                  handleSaveUserRole(companyAdmin);
                }
              }}
            />,
            <GridActionsCellItem
              key={params.id.toString()}
              icon={<CancelIcon />}
              id="update-company-admin-cancel-button"
              label={t("cancel")}
              onClick={() => {
                handleCancelEdit(params);
              }}
            />,
          ];
        }
        if (companyAdmin?.objectId !== authInformation?.objectId) {
          return [
            <GridActionsCellItem
              key={params.id.toString()}
              icon={<DeleteIcon />}
              id="update-company-admin-delete-button"
              label={t("delete")}
              color="inherit"
              onClick={() => {
                if (companyAdmin) {
                  handleDeleteUser(companyAdmin);
                }
              }}
            />,
          ];
        }
        return [];
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (!users && !companyAdmins) {
        setUsers(await getMyUsersAsync());
        setCompanyAdmins(
          companyData?.companyAdmins?.map((c: CompanyUserInformationResponse) => {
            return {
              ...c,
              id: nanoid(),
            };
          }) ?? []
        );
      }
    })();
  }, [users, setUsers, companyAdmins, companyData?.companyAdmins]);

  function handleSaveUserRole(companyAdmin: CompanyAdminRequest) {
    (async () => {
      const response = await createCompanyAdminAsync(companyAdmin.objectId);
      if (response && companyData) {
        const updatedCompanyAdmins = companyAdmins?.map((c: CompanyAdminRequest) =>
          c.id === companyAdmin.id ? { ...c, id: response.id } : c
        );
        setCompanyAdmins(updatedCompanyAdmins);
        setCompanyData({
          ...companyData,
          companyAdmins:
            updatedCompanyAdmins?.map((c: CompanyUserInformationResponse) => {
              return { ...c };
            }) ?? [],
        });
        setCompanyAdminsActiveRow({
          ...companyAdminsActiveRow,
          [response.id]: { mode: GridRowModes.View },
        });
        useSnackbarStore.getState().addSuccess(t("successfully-added-company-admin"));
      }
    })();
  }

  function handleDeleteUser(companyAdmin: CompanyAdminRequest) {
    (async () => {
      const response = await deleteCompanyAdminAsync(companyAdmin.objectId);
      if (response && companyData) {
        const filteredCompanyAdmins = companyAdmins?.filter(
          (row: CompanyAdminRequest) => row.id !== companyAdmin.id
        );
        setCompanyAdmins(filteredCompanyAdmins);
        setCompanyData({
          ...companyData,
          companyAdmins:
            filteredCompanyAdmins?.map((c: CompanyUserInformationResponse) => {
              return {
                ...c,
              };
            }) ?? [],
        });
        useSnackbarStore.getState().addSuccess(t("successfully-deleted-company-admin"));
      }
    })();
  }

  function handleCancelEdit(params: GridRowParams) {
    setCompanyAdmins(companyAdmins?.filter((row: CompanyAdminRequest) => row.id !== params.id.toString()));
  }

  function editUserRolesToolbar() {
    const handleAddUserClick = () => {
      const id = nanoid();
      if (companyAdmins) {
        setCompanyAdmins([
          ...companyAdmins,
          {
            id: id,
            objectId: "",
            name: "",
            emailAddress: "",
          },
        ]);
      }
      setCompanyAdminsActiveRow({
        ...companyAdminsActiveRow,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      });
    };
    return (
      <GridToolbarContainer>
        <Button
          id="update-company-admin-add-button"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddUserClick}
        >
          {t("add-admin")}
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  if (users === undefined) {
    return <LinearProgress id="update-company-admin-users-loading" />;
  }

  return (
    <Box style={{ height: 450, width: "100%" }} sx={{ mt: 2, mb: 3 }} id="update-company-admin-grid">
      <DataGrid
        columns={companyAdminsColumns}
        rows={companyAdmins ?? []}
        editMode="row"
        rowModesModel={companyAdminsActiveRow}
        onRowModesModelChange={(newModel: GridRowModesModel) => setCompanyAdminsActiveRow(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        slots={{
          toolbar: editUserRolesToolbar,
        }}
      />
    </Box>
  );
}
