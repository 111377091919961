import { EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import getSignInAccount from "../utils/getSignInAccount";
import getLoginRequest from "../utils/getLoginReqest";
import { authConfig, b2cPolicies } from "../config/authConfig";
import IdTokenClaims from "../types/IdTokenClaims";

const authInstance: PublicClientApplication = new PublicClientApplication(authConfig);

// Adds event listeners for when a user account is added or removed from localStorage (either from this tab, another tab or another window)
authInstance.enableAccountStorageEvents();
authInstance.addEventCallback((message: EventMessage) => {
  // Login when another tab or window triggered the sign-in flow
  if (message.eventType === EventType.ACCOUNT_ADDED && message.payload && "idTokenClaims" in message.payload) {
    const idTokenClaims: IdTokenClaims | undefined = message.payload.idTokenClaims;

    if (idTokenClaims?.tfp === b2cPolicies.names.signUpAndSignIn) {
      const signInAccount = getSignInAccount();

      if (signInAccount) {
        const request = getLoginRequest();
        authInstance.loginRedirect({
          ...request,
          authority: b2cPolicies.authorities.signUpAndSignIn,
          account: signInAccount,
        });
      }
    }

    // Logout when another tab or window triggered it
  } else if (message.eventType === EventType.ACCOUNT_REMOVED) {
    authInstance.logoutRedirect();
  }
});

export default authInstance;
