import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CompanyAddressResponse from "../types/CompanyAddressResponse";

type Props = {
  companyAddressDetails?: CompanyAddressResponse;
  type: string;
};

export default function CompanyAddressDetails(props: Readonly<Props>): JSX.Element | null {
  const { t } = useTranslation();

  if (!props.companyAddressDetails) {
    return null;
  }

  return (
    <Box>
      <Typography>
        {props.type} {t("address")}:
      </Typography>
      <List>
        <ListItem id={`company-${props.type}-address-line1`}>
          <ListItemText primary={t("line1")} secondary={props.companyAddressDetails?.line1} />
        </ListItem>
        {props.companyAddressDetails?.line2 && (
          <ListItem id={`company-${props.type}-address-line2`}>
            <ListItemText primary={t("line2")} secondary={props.companyAddressDetails?.line2} />
          </ListItem>
        )}
        {props.companyAddressDetails?.line3 && (
          <ListItem id={`company-${props.type}-address-line3`}>
            <ListItemText primary={t("line3")} secondary={props.companyAddressDetails?.line3} />
          </ListItem>
        )}
        <ListItem id={`company-${props.type}-address-country`}>
          <ListItemText primary={t("country")} secondary={props.companyAddressDetails?.country} />
        </ListItem>
      </List>
    </Box>
  );
}
