import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { maxLengthValidationMessage } from "../../../config/formValidationMessages";
import useAuthStore from "../../../stores/authStore";
import useSnackbarStore from "../../../stores/snackbarStore";
import createCompanyDataAsync from "../../company/api/createCompanyDataAsync";
import CreateCompanyDataRequest from "../../company/types/CreateCompanyDataRequest";
import { isUserCompanyAdmin } from "../../../utils/isUserCompanyAdmin";
import { isUserEecdCompanyAdmin } from "../../../utils/isUserEecdCompanyAdmin";
import CompanyAddressDetails from "../../company/components/CompanyAddressDetails";
import companyDataStore from "../../../stores/companyDataStore";
import CompanyUserInformationResponse from "../../user/types/CompanyUserInformationResponse";

export default function MyEecd(): JSX.Element {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const { authInformation } = useAuthStore();
  const { setCompanyData, companyData } = companyDataStore();
  const [isSkipped, setIsSkipped] = useState<boolean>(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateCompanyDataRequest>({
    defaultValues: {
      vat: "",
      duns: "",
      eoriNumber: "",
    },
  });

  const usersColumns: GridColDef[] = [
    {
      field: "user",
      headerName: t("name"),
      width: 200,
      editable: true,
      type: "singleSelect",
      renderCell: (params) =>
        companyData?.companyAdmins?.find((admin: CompanyUserInformationResponse) => admin.objectId === params.id)
          ?.name,
    },
  ];

  const isCompanyAdmin = useCallback((): boolean | undefined => {
    return isUserCompanyAdmin(authInformation);
  }, [authInformation]);

  const isEecdCompanyAdmin = useCallback((): boolean | undefined => {
    return isUserEecdCompanyAdmin(authInformation);
  }, [authInformation]);

  async function submitFormAsync(request: CreateCompanyDataRequest): Promise<void> {
    const response = await createCompanyDataAsync(request);
    if (response) {
      setCompanyData({ ...response });
      useSnackbarStore.getState().addSuccess(t("successfully-created-company-data"));
    }
  }

  if (!authInformation?.ninaUserDefaultCompany) {
    return (
      <Typography id="my-eecd-no-default-company">
        {t("not-member-of-eclic-community")} <br /> {t("contact-company-administrator")}
      </Typography>
    );
  }

  if (companyData === undefined) {
    return <LinearProgress id="my-eecd-company-data-loading" />;
  }

  if (!companyData) {
    if (!isCompanyAdmin()) {
      return (
        <Typography id="my-eecd-no-company-admin">
          {t("company-membership-requires-additional-information")} <br /> {t("contact-company-administrator")}
        </Typography>
      );
    } else {
      return (
        <Box>
          <Typography id="my-eecd-company-information-title" variant="h5">
            {t("company-information")}
          </Typography>
          <Typography id="my-eecd-missing-company-information">
            {t("fill-in-the-additional-eecd-company-information")} *
          </Typography>
          <Box
            sx={{ mt: 3 }}
            component="form"
            id="my-eecd-company-data-form"
            onSubmit={handleSubmit(submitFormAsync)}
            autoComplete="off"
          >
            <Controller
              control={control}
              name="vat"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: 1 }}
                  error={!!errors.vat}
                  helperText={errors.vat?.message}
                  id="my-eecd-company-data-form-vat"
                  label={t("vat")}
                  fullWidth
                />
              )}
              rules={{
                maxLength: maxLengthValidationMessage(32),
              }}
            />
            <Controller
              control={control}
              name="duns"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: 1 }}
                  error={!!errors.duns}
                  helperText={errors.duns?.message}
                  id="my-eecd-company-data-form-duns"
                  label={t("duns")}
                  fullWidth
                />
              )}
              rules={{
                maxLength: maxLengthValidationMessage(13),
              }}
            />
            <Controller
              control={control}
              name="eoriNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: 2 }}
                  error={!!errors.eoriNumber}
                  helperText={errors.eoriNumber?.message}
                  id="my-eecd-company-data-form-eori-number"
                  label={t("eori-number")}
                  fullWidth
                />
              )}
              rules={{
                maxLength: maxLengthValidationMessage(15),
              }}
            />
            <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
              <LoadingButton
                id="my-eecd-company-data-form-submit-button"
                onClick={() => setIsSkipped(false)}
                loading={isSubmitting && !isSkipped}
                variant="contained"
                type="submit"
              >
                {t("create")}
              </LoadingButton>
              <LoadingButton
                id="my-eecd-company-data-skip-button"
                onClick={() => {
                  setIsSkipped(true);
                  reset({ vat: "", duns: "", eoriNumber: "" });
                }}
                loading={isSubmitting && isSkipped}
                variant="contained"
                color="inherit"
                type="submit"
              >
                {t("skip-for-now")}
              </LoadingButton>
            </Stack>
            <Typography fontSize="small" color="gray" sx={{ mt: 2 }}>
              * {t("duns-and-vat-required-for-shippers-and-carriers")}
            </Typography>
          </Box>
        </Box>
      );
    }
  } else {
    return (
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography id="my-eecd-company-information-title" variant="h5" sx={{ mb: 0 }}>
            {t("company-information")}
          </Typography>
          {isCompanyAdmin() && (
            <IconButton
              id="my-eecd-company-information-edit-button"
              aria-label="edit"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => {
                navigate("/companydata/edit");
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <Typography id="my-eecd-registered-in-company" sx={{ mb: 3 }}>
          {t("part-of-eclic-community")}:
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <List id="my-eecd-company-data-details-list">
              <Typography>{t("company-details")} :</Typography>
              <ListItem id="my-eecd-company-data--name">
                <ListItemText primary={t("name")} secondary={companyData.name} />
              </ListItem>
              <ListItem id="my-eecd-company-data-vat" sx={{ pt: 0 }}>
                <ListItemText primary={t("vat")} secondary={companyData.vat} />
              </ListItem>
              <ListItem id="my-eecd-company-data-duns" sx={{ pt: 0 }}>
                <ListItemText primary={t("duns")} secondary={companyData.duns} />
              </ListItem>
              <ListItem id="my-eecd-company-data-eori-number" sx={{ pt: 0 }}>
                <ListItemText primary={t("eori-number")} secondary={companyData.eoriNumber} />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={12} md={4}>
            <List id="my-eecd-company-data-main-address-list">
              <CompanyAddressDetails companyAddressDetails={companyData.mainAddress} type={t("main")} />
              <ListItem id="my-eecd-company-data-main-email">
                <ListItemText primary={t("main-email")} secondary={companyData.mainEmail} />
              </ListItem>
            </List>
          </Grid>
          {companyData.billingEmail && (
            <Grid item sm={12} md={4}>
              <List id="my-eecd-company-data-billing-address-list">
                <CompanyAddressDetails companyAddressDetails={companyData.billingAddress} type={t("billing")} />
                <ListItem id="my-eecd-company-data-billing-email">
                  <ListItemText primary={t("billing-email")} secondary={companyData.billingEmail} />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography id="my-eecd-company-admins-title" variant="h5" sx={{ mb: 0 }}>
            {t("company-admins")}
          </Typography>
          {isEecdCompanyAdmin() && (
            <IconButton
              id="my-eecd-company-admins-edit-button"
              aria-label="edit"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => {
                navigate("/companydata/admins/edit");
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <Box style={{ height: 265, width: "100%" }} sx={{ mt: 2, mb: 3 }} id="update-user-roles-grid">
          <DataGrid
            columns={usersColumns}
            rows={companyData?.companyAdmins}
            getRowId={(a: CompanyUserInformationResponse) => a.objectId}
          />
        </Box>
      </Box>
    );
  }
}
