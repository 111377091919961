import RouteDefinition from "../types/RouteDefinition";
import { matchPath } from "react-router-dom";

export default function useGetCurrentRoute(routes: RouteDefinition[]) {
  function getCurrentRoute(): RouteDefinition | undefined {
    let currentRoute = routes
      .map((route: RouteDefinition) =>
        route.children?.find((subRoute) => matchPath(subRoute.path, location.pathname))
      )
      .filter((route) => route)[0];

    if (!currentRoute) {
      currentRoute = routes.find((route: RouteDefinition) => matchPath(route.path, location.pathname));
    }

    return currentRoute;
  }

  return getCurrentRoute();
}
