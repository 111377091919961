import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IMsalContext, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { equalsIgnoringCase } from "../../../utils/strings";
import useAuthStore from "../../../stores/authStore";
import { loginRequest } from "../../../config/authConfig";
import { dateFormat } from "../../../config/formatConfig";
import getUserInvitationAsync from "../api/getUserInvitationAsync";
import acceptUserInvitationAsync from "../api/acceptUserInvitationAsync";
import UserInvitationResponse from "../types/UserInvitationResponse";
import UserInvitationState from "../types/UserInvitationState";

export default function UserInvitation(): JSX.Element {
  const id: string = useParams().id as string;
  const { t } = useTranslation();
  const auth: IMsalContext = useMsal();
  const isAuthenticated: boolean = useIsAuthenticated();
  const { authInformation } = useAuthStore();
  const [userInvitation, setUserInvitation] = useState<UserInvitationResponse | null>();
  const [userHasDefaultCompany, setUserHasDefaultCompany] = useState<boolean>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!userInvitation) {
        const userInvitationResponse = await getUserInvitationAsync(id);
        if (
          isAuthenticated &&
          userInvitationResponse &&
          equalsIgnoringCase(userInvitationResponse.invitedEmail, authInformation?.emailAddress)
        ) {
          setUserHasDefaultCompany(!!authInformation?.ninaUserDefaultCompany);
        }
        setUserInvitation(userInvitationResponse);
      }
    })();
  }, [
    userInvitation,
    id,
    isAuthenticated,
    authInformation?.emailAddress,
    authInformation?.ninaUserDefaultCompany,
  ]);

  function onClickLoginRegisterLink(): void {
    const request = loginRequest;
    request.redirectStartPage = `/user-invitations/${id}`;
    auth.instance.loginRedirect(request);
  }

  async function acceptInvitation(): Promise<void> {
    setIsSubmitting(true);
    const response = await acceptUserInvitationAsync(id);
    if (response) {
      setIsAccepted(true);
    }
    setIsSubmitting(false);
  }

  if (userInvitation === undefined) {
    return <LinearProgress id="user-invitation-loading" />;
  }

  if (userInvitation === null) {
    return <Typography id="user-invitation-not-found">{t("user-invitation-not-found")}</Typography>;
  }

  if (isAccepted && !isSubmitting) {
    return <Typography id="user-invitation-accepted-text">{t("accepted-invite-login-again")}</Typography>;
  }

  if (userInvitation.state === UserInvitationState.Pending) {
    if (!isAuthenticated) {
      return (
        <Typography id="user-invitation-not-authenticated-text">
          {t("invited-to-join-company", { companyName: userInvitation.companyName ?? "a company" })}&nbsp;
          <Link id="user-invitation-login-register-link" onClick={onClickLoginRegisterLink} color="inherit" to="">
            {t("login-register")}
          </Link>
          &nbsp;{t("to-accept")}
        </Typography>
      );
    } else if (equalsIgnoringCase(userInvitation.invitedEmail, authInformation?.emailAddress)) {
      if (!userHasDefaultCompany) {
        return (
          <Box>
            <Typography id="user-invitation-join-text">
              {t("invited-to-join-company-accept-to-join", {
                companyName: userInvitation.companyName ?? "a company",
              })}
            </Typography>
            <LoadingButton
              id="user-invitation-accept-button"
              onClick={acceptInvitation}
              loading={isSubmitting}
              variant="contained"
              sx={{ mt: 1 }}
            >
              {t("accept")}
            </LoadingButton>
          </Box>
        );
      } else {
        return (
          <Typography id="user-invitation-multiple-companies-text">
            {t("multiple-companies-not-supported")}
          </Typography>
        );
      }
    } else {
      return <Typography id="user-invitation-wrong-user-text">{t("invite-not-for-you")}</Typography>;
    }
  }

  if (userInvitation.state === UserInvitationState.Accepted) {
    if (!isAuthenticated) {
      return <Typography id="user-invitation-not-pending-text">{t("invitation-no-longer-pending")}</Typography>;
    } else if (equalsIgnoringCase(userInvitation.invitedEmail, authInformation?.emailAddress)) {
      return (
        <Typography id="user-invitation-current-user-accepted-text">
          {t("accepted-invite-to-join-company", {
            companyName: userInvitation.companyName,
            dateTime: format(new Date(userInvitation.updatedOn ?? ""), dateFormat),
          })}
        </Typography>
      );
    } else {
      return (
        <Typography id="user-invitation-not-current-user-accepted-text">
          {t("invitation-no-longer-pending")}
        </Typography>
      );
    }
  }

  return <></>;
}
