import { Box, IconButton, Snackbar as SnackbarMui, SnackbarContent } from "@mui/material";
import useSnackbarStore from "../../../stores/snackbarStore";
import SnackbarType from "../../../types/SnackbarType";
import CloseIcon from "@mui/icons-material/Close";

export default function Snackbar(): JSX.Element {
  const { snackbar, dismiss } = useSnackbarStore();

  function convertTypeToColor(type: SnackbarType): string {
    switch (type) {
      case SnackbarType.success:
        return "Green";
      case SnackbarType.warning:
        return "Orange";
      case SnackbarType.error:
        return "FireBrick";
      case SnackbarType.info:
      default:
        return "DodgerBlue";
    }
  }

  return (
    <Box>
      {snackbar && (
        <SnackbarMui
          key={snackbar.id}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open
          onClose={(_, reason) => {
            if (reason === "clickaway") {
              return;
            }
            dismiss();
          }}
        >
          <SnackbarContent
            message={snackbar.text}
            style={{
              backgroundColor: convertTypeToColor(snackbar.type),
            }}
            action={
              <IconButton onClick={() => dismiss()} color="inherit" size="large">
                <CloseIcon />
              </IconButton>
            }
          />
        </SnackbarMui>
      )}
    </Box>
  );
}
