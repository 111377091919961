import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

type Props = {
  dialogId: string;
  title: string;
  description: string;
  confirmDialogOpen: boolean;
  handleCloseConfirmDialog(isConfirmed: boolean): void;
};

export default function ConfirmDialog(props: Readonly<Props>): JSX.Element {
  const { t } = useTranslation();
  const { dialogId, title, description, confirmDialogOpen, handleCloseConfirmDialog } = props;

  return (
    <div>
      <Dialog id={dialogId} open={confirmDialogOpen} onClose={() => handleCloseConfirmDialog(false)}>
        <DialogTitle id="confirm-dialog-title">{t(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">{t(description)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmDialog(false)}>{t("cancel")}</Button>
          <Button onClick={() => handleCloseConfirmDialog(true)}>{t("confirm")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
