import { RedirectRequest } from "@azure/msal-browser";
import { loginRequest } from "../config/authConfig";

export default function getLoginRequest(): RedirectRequest {
  const request = loginRequest;

  // By default, the login request will redirect to the page that initiated the request.
  // For some pages, we want to redirect to the dashboard instead.
  const redirectToDashboardForPages: string[] = ["/", "/signin-oidc", "/signout-callback-oidc"];
  if (redirectToDashboardForPages.indexOf(location.pathname) !== -1) {
    request.redirectStartPage = "/my-eecd";
  }

  return request;
}
