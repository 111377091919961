import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import { nanoid } from "nanoid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import useSnackbarStore from "../../../stores/snackbarStore";
import getUserAsync from "../api/getUserAsync";
import UserResponse from "../types/UserResponse";
import UserRoleRequest from "../../user/types/UserRoleRequest";
import RoleResponse from "../../role/types/RoleResponse";
import { deleteUserRoleAsync } from "../../user/api/deleteUserRoleAsync";
import { createUserRoleAsync } from "../api/createUserRoleAsync";
import { updateUserRoleAsync } from "../../user/api/updateUserRoleAsync";
import getRolesByTypeAsync from "../../role/api/getRolesByTypeAsync";
import UserRoleResponse from "../../user/types/UserRoleResponse";
import UpsertUserRequest from "../types/UpsertUserRequest";
import getLanguagesAsync from "../../language/api/getLanguagesAsync";
import getTimeZonesAsync from "../../timezone/api/getTimeZonesAsync";
import { upsertUserAsync } from "../api/upsertUserAsync";
import LanguageResponse from "../../language/types/LanguageResponse";

export default function UpdateUser(): JSX.Element {
  const id: string = useParams().id as string;
  const { t } = useTranslation();
  const [languages, setLanguages] = useState<LanguageResponse[]>();
  const [timeZones, setTimeZones] = useState<string[]>();
  const [user, setUser] = useState<UserResponse | null>();
  const [roles, setRoles] = useState<RoleResponse[] | null>();
  const [userRoles, setUserRoles] = useState<UserRoleRequest[]>([]);
  const [userRolesDefault, setUserRolesDefault] = useState<UserRoleRequest[]>([]);
  const [rolesActiveRow, setRolesActiveRow] = useState<GridRowModesModel>({});

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UpsertUserRequest>({
    defaultValues: {
      preferredLanguage: "",
      timeZone: "",
    },
  });

  const userRolesColumns: GridColDef[] = [
    {
      field: "role",
      headerName: t("role"),
      width: 200,
      editable: true,
      type: "singleSelect",
      renderCell: (params) => userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id)?.roleName,
      renderEditCell: (params) => {
        const userRole = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id);
        const filteredRoles =
          roles?.filter(
            (role: RoleResponse) =>
              !userRoles?.some(
                (userRole: UserRoleRequest) => userRole.id !== params.id && role.id === userRole.roleId
              )
          ) ?? [];
        return (
          <Select
            id="update-user-role-column"
            value={userRole?.roleId}
            fullWidth
            error={!userRole?.roleId}
            onChange={(e) => {
              const role = roles?.find((r: RoleResponse) => r.id === e.target.value);
              if (role) {
                setUserRoles([
                  ...(userRoles?.map((userRole: UserRoleRequest) =>
                    params.id === userRole.id ? { ...userRole, roleId: role.id, roleName: role.name } : userRole
                  ) ?? []),
                ]);
              }
            }}
          >
            {filteredRoles?.map((role: RoleResponse) => {
              return (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              );
            })}
            ;
          </Select>
        );
      },
    },
    {
      field: "isMainUser",
      headerName: t("is-main-user"),
      align: "left",
      headerAlign: "left",
      width: 180,
      editable: true,
      renderCell: (params) => {
        const isMainUser = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id)?.isMainUser;
        return <Checkbox checked={isMainUser} disabled={true} />;
      },
      renderEditCell: (params) => {
        const userRole = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id);
        return (
          <Checkbox
            id="update-user-role-is-main-user-column"
            checked={userRole?.isMainUser}
            sx={{ mb: 1 }}
            onChange={(e) => {
              setUserRoles(
                userRoles?.map((userRole: UserRoleRequest) =>
                  params.id === userRole.id
                    ? {
                        ...userRole,
                        isMainUser: e.target.checked,
                        defaultIsMainUser: e.target.defaultChecked,
                      }
                    : userRole
                ) ?? []
              );
            }}
          />
        );
      },
    },
    {
      field: "options",
      headerName: "",
      type: "actions",
      flex: 1,
      align: "right",
      getActions: (params: GridRowParams) => {
        const isInEditMode = rolesActiveRow[params.id.toString()]?.mode === GridRowModes.Edit;
        const userRole = userRoles?.find((userRole: UserRoleRequest) => userRole.id === params.id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={params.id.toString()}
              icon={<SaveIcon />}
              disabled={!userRole?.roleId}
              id="update-user-role-save-button"
              label={t("save")}
              onClick={() => {
                const savedRow = userRoles?.find((row: UserRoleRequest) => row.id === params.id.toString());
                if (savedRow) {
                  handleSaveUserRole(params.id.toString(), savedRow);
                }
              }}
            />,
            <GridActionsCellItem
              key={params.id.toString()}
              icon={<CancelIcon />}
              id="update-user-role-cancel-button"
              label={t("cancel")}
              onClick={() => {
                handleCancelEdit(params);
              }}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key={params.id.toString()}
            icon={<EditIcon />}
            id="update-user-role-edit-button"
            label={t("edit")}
            onClick={() => {
              setRolesActiveRow({
                ...rolesActiveRow,
                [params.id.toString()]: { mode: GridRowModes.Edit },
              });
            }}
          />,
          <GridActionsCellItem
            key={params.id.toString()}
            icon={<DeleteIcon />}
            id="update-user-role-delete-button"
            label={t("delete")}
            color="inherit"
            onClick={() => {
              handleDeleteUserRole(params.id.toString());
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (!user && !roles && !languages && !timeZones) {
        const userResponse = await getUserAsync(id);
        if (userResponse?.userRoles) {
          const requests: UserRoleRequest[] = userResponse.userRoles.map((r: UserRoleResponse) => {
            return {
              id: r.id,
              roleId: r.role.id,
              roleName: r.role.name,
              isMainUser: r.isMainUser,
              isNew: false,
            };
          });
          setUserRoles(requests);
          setUserRolesDefault(requests);
        }
        setUser(userResponse);
        reset({
          preferredLanguage: userResponse.preferredLanguage?.code ?? "",
          timeZone: userResponse.timezone ?? "",
        });
        setRoles(await getRolesByTypeAsync("User"));
        setLanguages([{ name: "", code: "" }, ...(await getLanguagesAsync())]);
        setTimeZones(["", ...(await getTimeZonesAsync())]);
      }
    })();
  }, [user, id, roles, languages, timeZones, reset]);

  function handleDeleteUserRole(userRoleId: string) {
    (async () => {
      const response = await deleteUserRoleAsync(id, userRoleId);
      if (response) {
        setUserRoles(userRoles?.filter((row: UserRoleRequest) => row.id !== userRoleId));
        useSnackbarStore.getState().addSuccess(t("successfully-deleted-user-role"));
      }
    })();
  }

  function handleCancelEdit(params: GridRowParams) {
    setRolesActiveRow({
      ...rolesActiveRow,
      [params.id.toString()]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    if (params.row.isNew) {
      setUserRoles(userRoles?.filter((row: UserRoleRequest) => row.id !== params.id.toString()));
    } else {
      const userRoleDefault = userRolesDefault.find((u: UserRoleRequest) => u.id === params.id.toString());
      if (userRoleDefault) {
        setUserRoles([
          ...userRoles.map((u: UserRoleRequest) => (u.id === params.id.toString() ? userRoleDefault : u)),
        ]);
      }
    }
  }

  function handleSaveUserRole(userRoleId: string, request: UserRoleRequest) {
    (async () => {
      const userRole = userRoles.find((u: UserRoleRequest) => u.id === userRoleId);
      if (request.isNew) {
        const response = await createUserRoleAsync(id, {
          roleId: request.roleId,
          isMainUser: request.isMainUser,
        });
        if (response && userRole) {
          const newUserRole: UserRoleRequest = {
            ...userRole,
            id: response.id,
            roleId: response.role.id,
            roleName: response.role.name,
            isNew: false,
          };
          setUserRoles(userRoles.map((u: UserRoleRequest) => (u.id === request.id ? newUserRole : u)));
          setUserRolesDefault(
            userRolesDefault.map((u: UserRoleRequest) => (u.id === request.id ? newUserRole : u))
          );
          setRolesActiveRow({
            ...rolesActiveRow,
            [response.id]: { mode: GridRowModes.View },
          });
          useSnackbarStore.getState().addSuccess(t("successfully-added-new-user-role"));
        }
      } else {
        const response = await updateUserRoleAsync(id, userRoleId, {
          roleId: request.roleId,
          isMainUser: request.isMainUser,
        });
        if (response && userRole) {
          setRolesActiveRow({
            ...rolesActiveRow,
            [request.id]: { mode: GridRowModes.View },
          });
          useSnackbarStore.getState().addSuccess(t("successfully-edited-user-role"));
          setUserRolesDefault(userRolesDefault.map((u: UserRoleRequest) => (u.id === userRoleId ? userRole : u)));
        }
      }
    })();
  }

  function editUserRolesToolbar() {
    const handleAddUserRoleClick = () => {
      const tempId = nanoid();
      const newUserRole: UserRoleRequest = {
        id: tempId,
        roleId: "",
        roleName: "",
        isMainUser: false,
        isNew: true,
      };
      if (userRoles) {
        setUserRoles([...userRoles, newUserRole]);
        setUserRolesDefault([...userRoles, newUserRole]);
      }
      setRolesActiveRow({
        ...rolesActiveRow,
        [tempId]: { mode: GridRowModes.Edit, fieldToFocus: "role" },
      });
    };
    return (
      <GridToolbarContainer>
        <Button
          id="update-user-role-add-button"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddUserRoleClick}
          disabled={roles?.every((role: RoleResponse) =>
            userRoles?.map((userRole: UserRoleRequest) => userRole.roleId).includes(role.id)
          )}
        >
          {t("add-user-role")}
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  async function submitFormAsync(request: UpsertUserRequest): Promise<void> {
    const response = await upsertUserAsync(id, request);
    if (response) {
      useSnackbarStore.getState().addSuccess(t("successfully-updated-role"));
    }
  }

  if (user === undefined || userRoles === undefined || languages === undefined || timeZones === undefined) {
    return <LinearProgress id="user-loading" />;
  }

  if (!user) {
    return <Typography id="user-not-found">{t("user-not-found")}</Typography>;
  }

  return (
    <Box>
      <Typography id="update-user-title" variant="h4" color="secondary" sx={{ mb: 2 }}>
        {t("update")} {user.name}
      </Typography>
      <Typography id="update-user-details-title" variant="h5">
        {t("user-details")}
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{ mt: 1, mb: 4 }}
        component="form"
        id="update-user-form"
        onSubmit={handleSubmit(submitFormAsync)}
        autoComplete="off"
      >
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="preferredLanguage"
              render={() => (
                <FormControl fullWidth>
                  <InputLabel id="update-user-form-preferred-language-select-label">
                    {t("preferred-language")}
                  </InputLabel>
                  <Select
                    sx={{ mb: 2 }}
                    id="update-user-form-preferred-language-select"
                    labelId="update-user-form-preferred-language-select-label"
                    label={t("preferred-language")}
                    value={getValues("preferredLanguage")}
                    onChange={(event) => {
                      setValue("preferredLanguage", event.target.value);
                    }}
                  >
                    {languages.map((language: LanguageResponse) => {
                      return (
                        <MenuItem key={language.code} value={language.code} sx={{ minHeight: "36px!important" }}>
                          {language.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="timeZone"
              render={() => (
                <FormControl fullWidth>
                  <InputLabel id="update-user-form-time-zone-select-label">{t("time-zone")}</InputLabel>
                  <Select
                    sx={{ mb: 2 }}
                    id="update-user-form-time-zone-select"
                    labelId="update-user-form-time-zone-select-label"
                    label={t("timeZone")}
                    value={getValues("timeZone")}
                    onChange={(event) => {
                      setValue("timeZone", event.target.value);
                    }}
                  >
                    {timeZones?.map((timeZone: string) => {
                      return (
                        <MenuItem key={timeZone} value={timeZone} sx={{ minHeight: "36px!important" }}>
                          {timeZone}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              id="update-user-form-submit-button"
              loading={isSubmitting}
              variant="contained"
              type="submit"
            >
              {t("update")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Typography id="update-user-roles-title" variant="h5">
        {t("user-roles")}
      </Typography>
      <Box style={{ height: 265, width: "100%" }} sx={{ mt: 2, mb: 3 }} id="update-user-roles-grid">
        <DataGrid
          columns={userRolesColumns}
          rows={userRoles}
          editMode="row"
          rowModesModel={rolesActiveRow}
          onRowModesModelChange={(newModel: GridRowModesModel) => setRolesActiveRow(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          slots={{
            toolbar: editUserRolesToolbar,
          }}
        />
      </Box>
    </Box>
  );
}
