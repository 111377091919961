import { createTheme, Theme } from "@mui/material";

const theme: Theme = createTheme({
  palette: {
    primary: {
      light: "#476499",
      main: "#123a6b",
      dark: "#001540",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#8fccff",
      main: "#5b9bd5",
      dark: "#1e6da3",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        gutterBottom: true,
      },
    },
  },
});

export default theme;
