import { Box, Button, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import useSnackbarStore from "../../../stores/snackbarStore";
import companyDataStore from "../../../stores/companyDataStore";
import UpdateCompanyDataRequest from "../types/UpdateCompanyDataRequest";
import updateCompanyDataAsync from "../api/updateCompanyDataAsync";
import { maxLengthValidationMessage } from "../../../config/formValidationMessages";

export default function UpdateCompanyData(): JSX.Element {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const { setCompanyData, companyData } = companyDataStore();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdateCompanyDataRequest>({
    defaultValues: {
      vat: companyData?.vat ?? "",
      duns: companyData?.duns ?? "",
      eoriNumber: companyData?.eoriNumber ?? "",
    },
  });

  async function submitFormAsync(request: UpdateCompanyDataRequest): Promise<void> {
    const response = await updateCompanyDataAsync(request);
    if (response && companyData) {
      setCompanyData({ ...companyData, ...request });
      useSnackbarStore.getState().addSuccess(t("successfully-updated-company-data"));
    }
  }

  if (companyData === undefined) {
    return <LinearProgress id="company-data-loading" />;
  }

  if (!companyData) {
    return <Typography id="company-data-not-found">{t("company-data-not-found")}</Typography>;
  }

  return (
    <Box>
      <Typography id="update-missing-company-information">
        {t("update-the-additional-eecd-company-information")} *
      </Typography>
      <Box
        sx={{ mt: 3 }}
        component="form"
        id="update-company-data-form"
        onSubmit={handleSubmit(submitFormAsync)}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="vat"
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 1 }}
              error={!!errors.vat}
              helperText={errors.vat?.message}
              id="update-company-data-form-vat"
              label={`${t("vat")} *`}
              fullWidth
            />
          )}
          rules={{
            maxLength: maxLengthValidationMessage(32),
          }}
        />
        <Controller
          control={control}
          name="duns"
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 1 }}
              error={!!errors.duns}
              helperText={errors.duns?.message}
              id="update-company-data-form-duns"
              label={`${t("duns")} *`}
              fullWidth
            />
          )}
          rules={{
            maxLength: maxLengthValidationMessage(13),
          }}
        />
        <Controller
          control={control}
          name="eoriNumber"
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 2 }}
              error={!!errors.eoriNumber}
              helperText={errors.eoriNumber?.message}
              id="update-company-data-form-eori-number"
              label={`${t("eori-number")} *`}
              fullWidth
            />
          )}
          rules={{
            maxLength: maxLengthValidationMessage(15),
          }}
        />
        <Stack spacing={2} direction="row">
          <LoadingButton
            id="update-company-data-form-submit-button"
            loading={isSubmitting}
            variant="contained"
            type="submit"
          >
            {t("update")}
          </LoadingButton>
          <Button id="update-company-data-form-cancel-button" onClick={() => navigate("/my-eecd")}>
            {t("cancel")}
          </Button>
        </Stack>
        <Typography fontSize="small" color="gray" sx={{ mt: 2 }}>
          * {t("duns-and-vat-required-for-shippers-and-carriers")}
        </Typography>
      </Box>
    </Box>
  );
}
