import { useState } from "react";
import { Box, Divider, List, Toolbar } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import HealingIcon from "@mui/icons-material/Healing";
import ChatIcon from "@mui/icons-material/Chat";
import SidebarItem from "./SidebarItem";
import SidebarExternalItem from "./SidebarExternalItem";
import RouteDefinition from "../../../types/RouteDefinition";
import useGetCurrentRoute from "../../../hooks/useGetCurrentRoute";
import { useTranslation } from "react-i18next";

type Props = {
  routes: RouteDefinition[];
};

export default function SidebarContent(props: Readonly<Props>): JSX.Element {
  const { t } = useTranslation();

  const currentRoute: RouteDefinition | undefined = useGetCurrentRoute(props.routes);
  const isAuthenticated: boolean = useIsAuthenticated();
  const [selectedRoutePath, setSelectedRoutePath] = useState(currentRoute?.path);

  return (
    <Box id="sidebar-content" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Toolbar />
      <Divider />

      <List id="sidebar-content-list" sx={{ flexGrow: 1 }}>
        {props.routes
          .filter(() => isAuthenticated)
          .map((route) => (
            <SidebarItem
              key={route.path}
              route={route}
              setSelectedRoutePath={setSelectedRoutePath}
              selectedRoutePath={selectedRoutePath}
            />
          ))}
      </List>
      <List id="sidebar-content-list-bottom">
        <SidebarExternalItem
          buttonId="sidebar-content-list-bottom-statusPage-button"
          href="https://status.nxtport.com"
          text={t("status-page")}
          icon={<HealingIcon id="sideBarIconStatusPage" />}
        />
        <SidebarExternalItem
          buttonId="sidebar-content-list-bottom-support-button"
          href="https://nxtport-international.zendesk.com/hc/en-us"
          text={t("support")}
          icon={<ChatIcon id="sideBarIconSupport" />}
        />
      </List>
    </Box>
  );
}
