import RouteDefinition from "../../../types/RouteDefinition";
import GroupIcon from "@mui/icons-material/Group";
import PeopleIcon from "@mui/icons-material/People";
import { eecdCompanyAdmin, userAdministrator } from "../../../config/roles";
import UsersOverview from "./UsersOverview";
import UpdateUser from "./UpdateUser";

export const routes: RouteDefinition[] = [
  {
    path: "/users",
    element: <UsersOverview />,
    title: "users",
    icon: <GroupIcon id="groupIcon" />,
    showInSidebar: true,
    allowedRoles: [eecdCompanyAdmin, userAdministrator],
  },
  {
    path: "/users/:id/edit",
    element: <UpdateUser />,
    icon: <PeopleIcon id="peopleIcon" />,
    allowedRoles: [eecdCompanyAdmin, userAdministrator],
    headerButton: {
      content: "users",
      path: "/users",
    },
  },
];
