import {
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import useSnackbarStore from "../../../stores/snackbarStore";
import getCommunityUserRolesAsync from "../api/getCommunityUserRolesAsync";
import CommunityUserRolesResponse from "../types/CommunityUserRolesResponse";
import CompanyResponse from "../../company/types/CompanyResponse";
import getCompaniesInEclicCommunityAsync from "../../company/api/getCompaniesInEclicCommunityAsync";
import AssignCommunityUserRolesDialog from "../components/AssignCommunityUserRolesDialog";
import UpsertCommunityUserRolesRequest from "../types/UpsertCommunityUserRolesRequest";
import { upsertCommunityUserRolesAsync } from "../api/upsertCommunityUserRolesAsync";

export default function CommunityUserRolesOverview(): JSX.Element {
  const { t } = useTranslation();
  const [users, setUsers] = useState<CommunityUserRolesResponse[] | null>();
  const [user, setUser] = useState<CommunityUserRolesResponse | null>();
  const [assignCommunityUserRolesDialogOpen, setAssignCommunityUserRolesDialogOpen] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyResponse[] | null>();

  useEffect(() => {
    (async () => {
      if (!users) {
        setUsers(await getCommunityUserRolesAsync());
      }
    })();
  }, [users]);

  async function handleCloseAssignCommunityUserRolesDialog(
    request: UpsertCommunityUserRolesRequest | null
  ): Promise<void> {
    if (request) {
      const response = await upsertCommunityUserRolesAsync(request.userObjectId, request);
      if (response && users) {
        useSnackbarStore.getState().addSuccess(t("successfully-assigned-community-user-roles"));
        if (user) {
          setUsers(
            users
              .map((u: CommunityUserRolesResponse) =>
                u.objectId === user.objectId
                  ? {
                      ...u,
                      isCommunityAdmin: request.isCommunityAdmin,
                      isCommunityEquipmentMasterDataAdmin: request.isCommunityEquipmentMasterDataAdmin,
                    }
                  : u
              )
              .filter(
                (u: CommunityUserRolesResponse) => u.isCommunityAdmin || u.isCommunityEquipmentMasterDataAdmin
              )
          );
        } else {
          setUsers([
            ...users,
            {
              objectId: request.userObjectId,
              companyId: request.companyId,
              name: request.userName,
              isCommunityAdmin: request.isCommunityAdmin,
              isCommunityEquipmentMasterDataAdmin: request.isCommunityEquipmentMasterDataAdmin,
            },
          ]);
        }
      }
    }
    setUser(null);
    setAssignCommunityUserRolesDialogOpen(false);
  }

  if (users === undefined) {
    return <LinearProgress id="community-user-roles-loading" />;
  }

  return (
    <Box>
      <Button
        id="community-user-roles-create-button"
        onClick={() => {
          (async () => {
            setAssignCommunityUserRolesDialogOpen(true);
            setCompanies(await getCompaniesInEclicCommunityAsync());
          })();
        }}
        color="primary"
        variant="contained"
        sx={{ mb: 2, mt: 1 }}
      >
        {t("assign-community-roles")}
      </Button>
      {users && users.length > 0 ? (
        <Box>
          <Table id="community-user-roles-table" sx={{ mb: 2 }} style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("is-community-admin")}</TableCell>
                <TableCell sx={{ width: "40%" }}>{t("is-community-equipment-master-data-admin")}</TableCell>
                <TableCell sx={{ width: "60px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: CommunityUserRolesResponse) => (
                <TableRow key={user.objectId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell id="community-user-roles-name">
                    <Tooltip title={user.name} followCursor>
                      <Typography noWrap>{user.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell id="community-user-roles-is-community-admin">
                    <Checkbox
                      id="community-user-roles-is-community-admin-checkbox"
                      checked={user.isCommunityAdmin}
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell id="community-user-roles-is-community-equipment-master-data-admin">
                    <Checkbox
                      id="community-user-roles-is-community-equipment-master-data-admin-checkbox"
                      checked={user.isCommunityEquipmentMasterDataAdmin}
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      id="community-user-roles-options"
                      aria-label="edit"
                      size="small"
                      sx={{ pr: 0, minWidth: "auto", float: "right" }}
                      onClick={() => {
                        (async () => {
                          setAssignCommunityUserRolesDialogOpen(true);
                          setUser(user);
                          setCompanies(await getCompaniesInEclicCommunityAsync());
                        })();
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Typography id="community-user-roles-not-found">{t("no-users-found")}</Typography>
      )}
      <AssignCommunityUserRolesDialog
        user={user}
        users={users}
        companies={companies}
        assignCommunityUserRolesDialogOpen={assignCommunityUserRolesDialogOpen}
        handleCloseAssignCommunityUserRolesDialog={handleCloseAssignCommunityUserRolesDialog}
      />
    </Box>
  );
}
