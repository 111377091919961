export const ninaConsoleUser: string = "NiNa.Console.User";
export const ninaConsoleAdmin: string = "NiNa.Console.Admin";

export const companyUser: string = "Customers.CompanyUser";
export const companyAdmin: string = "Customers.CompanyAdmin";

export const eclicCommunityAdmin: string = "Products.EclicCommunityAdmin";

export const communityAdmin: string = "CommunityAdmin";
export const eecdCompanyAdmin: string = "EecdCompanyAdmin";
export const userAdministrator: string = "UserAdministrator";
